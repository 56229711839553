import { loadSlimSelect } from "../shared/slim-select-wrapper";
document.addEventListener('DOMContentLoaded', function() {
  if(document.querySelector('select.load-fields')) {
    let ajaxSelects = document.querySelectorAll('select.load-fields')
    ajaxSelects.forEach(ajaxSelect => {
      ajaxSelect.addEventListener('change', function(e) {
        //call ajax method to get the fields for the type
        e.preventDefault();
        let section = this.dataset.section;
  
        const url = new URL(document.location.origin+this.dataset.url);
        url.searchParams.append('type', e.target.value);
        const sectionEl = document.querySelector(section)
        fetch(url, {
          headers: {
            "Accept": "text/html",
            "Turbo-Frame": section
          }
        }).then(response => response.text()).then(body => {
          sectionEl.innerHTML = body
          //add any post load ajax code here
          loadSlimSelect("select.slim-select", sectionEl)    
        });
      });        
    });
  }
});