import { documentOn } from "../shared/jquery-alternative";
document.addEventListener('DOMContentLoaded', function() {
  documentOn('change', '.location_notification', function(e) {
    let location_id = e.target.value;
    if (location_id === '') {
      location_id = 'manager';
    }
    var request = new XMLHttpRequest();
    request.open('GET', `/manager/notifications/location_notifications.js?location_id=${ location_id }`, true);
    request.setRequestHeader('Accept', 'text/javascript');
    const auth_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    request.setRequestHeader('X-CSRF-Token', auth_token);
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.onload = function() {
      if (this.status >= 200 && this.status < 400) {
        // Success!
        eval(this.response);
      } else {
        // We reached our target server, but it returned an error
      }
    };
    request.send();
  });

  documentOn('change', '.parent.online', function(e) {
    if (e.target.classList.contains('push_settings')) {
      var list = document.querySelectorAll(".push_settings.online");
      if (e.target.checked) {
        update_checkboxes(list, true);
      }
      else {
        update_checkboxes(list, false);
      }
    }
    else if (this.classList.contains('email_settings')) {
      var list = document.querySelectorAll(".email_settings.online");
      if (e.target.checked) {
        update_checkboxes(list, true);
      }
      else {
        update_checkboxes(list, false);
      }
    }
    else if (this.classList.contains('sms_settings')) {
      var list = document.querySelectorAll(".sms_settings.online");
      if (this.checked) {
        update_checkboxes(list, true);
      }
      else {
        update_checkboxes(list, false);
      }
    }
    else if (this.classList.contains('mattermost_settings')) {
      var list = document.querySelectorAll(".mattermost_settings.online");
      if (e.target.checked) {
        update_checkboxes(list, true);
      }
      else {
        update_checkboxes(list, false);
      }
    }
  });

  documentOn('change', '.parent.pos', function(e) {
    if (this.classList.contains('push_settings')) {
      var list = document.querySelectorAll(".push_settings.pos");
      if (e.target.checked) {
        update_checkboxes2(list, true);
      }
      else {
        update_checkboxes2(list, false);
      }
    }
    else if (this.classList.contains('email_settings')) {
      var list = document.querySelectorAll(".email_settings.pos");
      if (e.target.checked) {
        update_checkboxes2(list, true);
      }
      else {
        update_checkboxes2(list, false);
      }
    }
    else if (this.classList.contains('sms_settings')) {
      var list = document.querySelectorAll(".sms_settings.pos");
      if (e.target.checked) {
        update_checkboxes2(list, true);
      }
      else {
        update_checkboxes2(list, false);
      }
    }
    else if (this.classList.contains('mattermost_settings')) {
      var list = document.querySelectorAll(".mattermost_settings.pos");
      if (e.target.checked) {
        update_checkboxes2(list, true);
      }
      else {
        update_checkboxes2(list, false);
      }
    }
  });

  var update_checkboxes;
  update_checkboxes = function(list, value) {
    Array.from(list).forEach(checkbox => {
      checkbox.checked = value;
    });
  };

  var update_checkboxes2;
  update_checkboxes2 = function(list, value) {
    Array.from(list).forEach(checkbox2 => {
      checkbox2.checked = value;
    });
  };

  documentOn('click', '.notif-btn', function() {
    let data = new FormData();
    let url = this.dataset.url;
    let list = document.querySelectorAll(".location-notification-settings input[type='checkbox']");
    list.forEach(checkbox => {
      data.append(checkbox.name, checkbox.checked);
    });
    var request = new XMLHttpRequest();
    request.open('PUT', url, true);
    const auth_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    request.setRequestHeader('X-CSRF-Token', auth_token);
    request.onload = function() {
      if (this.status >= 200 && this.status < 400) {
        // Success!
        window.location.reload();
      } else {
        // We reached our target server, but it returned an error

      }
    };
    request.send(data);
  });
});
