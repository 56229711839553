import { documentOn } from "../shared/jquery-alternative";
document.addEventListener('DOMContentLoaded', function() {

  documentOn('change', '#all_locations', function(e) {
    var list = document.querySelectorAll(".location-data-settings input[type='checkbox']");
    if (e.currentTarget.checked) {
      update_checkboxes(list, true);
    }
    else {
      update_checkboxes(list, false);
    }
  });

  var update_checkboxes;
  update_checkboxes = function(list, value) {
    Array.from(list).forEach(checkbox => {
      checkbox.checked = value;
    });
  };

  documentOn('click', '.data-set-btn', function(e) {
    e.currentTarget.disabled = true;
    let locations_checked = [];
    let locations_unchecked = [];
    let url = e.currentTarget.dataset.url;
    let list = document.querySelectorAll(".location-data-settings input[type='checkbox']");
    list.forEach(checkbox => {
      if (checkbox.checked == true) {
        locations_checked.push(checkbox.id);
      }
      else {
        locations_unchecked.push(checkbox.id);
      }
    });
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locations: {
          'locations_checked': locations_checked,
          'locations_unchecked': locations_unchecked
        }
      })
    }).then(response => {
      window.location.reload();
    }).catch(error => {
      console.error('Error:', error);
    });
  });
});
