import $ from "../shared/jquery-global";
import './stripe-payment.js';
import './announcements';
import './notification_settings';
import './data_settings';
import './manager-users';
import './pos_design';
import './package_currency';

import '../shared/social';

import './qr-code-handler';
import './employee';
import './supplier';
import './rider';
import './pos_templates/location';
import './tax_settings';
import './firebase-notifications';
import { documentOn, getScript } from "../shared/jquery-alternative";

// import M from "../shared/materialize-global";
import { FormSelect, Toast } from "@materializecss/materialize";

document.addEventListener('DOMContentLoaded', function() {

  document.getElementById('supplier_payment_payment_mode')?.addEventListener('change', function(e) {
    const parent_element = e.currentTarget.closest('form');
    if (this.value == 'Cheque') {
      parent_element.querySelector('div.bulk_payment_similar_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_cheque_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_credit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_bank_deposit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_other_attributes').style.display = 'none';
    } else if (this.value == 'Credit Card') {
      parent_element.querySelector('div.bulk_payment_credit_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_similar_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_cheque_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_bank_deposit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_other_attributes').style.display = 'none';
    } else if (this.value == 'Bank Deposit') {
      parent_element.querySelector('div.bulk_payment_similar_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_bank_deposit_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_cheque_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_credit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_other_attributes').style.display = 'none';
    } else {
      parent_element.querySelector('div.bulk_payment_other_attributes').style.display = '';
      parent_element.querySelector('div.bulk_payment_credit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_similar_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_bank_deposit_attributes').style.display = 'none';
      parent_element.querySelector('div.bulk_payment_cheque_attributes').style.display = 'none';
    }
  });

  documentOn('change', '.enable', function(e) {
    e.target.closest('form').submit();
  });

  documentOn('click', '.fav', function(e) {
    var url = '/favorites/';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        favorite: {
          obj_id: this.dataset.location, 
          obj_type: 'Location'
        }
      })
    })
    .catch(error => {
      console.error('Error:', error);
    })
  });

  if (document.querySelector('.infinite-scrolling')) {
    if (document.querySelector('.pagination')) {
      window.addEventListener('scroll', function() {
        const url = document.querySelector('.pagination li a[rel=\'next\']').getAttribute('href');
        if (url && ($(window).scrollTop() > ($(document).height() - $(window).height() - 50))) {
          document.querySelectorAll('.pagination').forEach(el => {
            el.textContent = 'Please Wait...';
          });
          getScript(url);
        }
      });
      window.dispatchEvent(new Event('scroll'));;
    }
  }

  if (document.querySelector(".abc")) {
    const fileObject = {};
    document.querySelector(".abc").addEventListener('change', function(event) {
      const file_preview = event.currentTarget.parentNode.querySelector(".image-c-container").querySelector('img');
      const input = event.currentTarget;
      const file = input.files[0];
      if (!!!file) { return; }
      fileObject.filetype_image = file.type;
      fileObject.filename_image = file.name;
      const reader = new FileReader();
      reader.onload = function(e) {
        const image_base64 = e.target.result;
        file_preview.setAttribute("src", image_base64);
        //save: false // disable plugin
        //new CarrierWaveCropper()
      };
      reader.readAsDataURL(file);
    });
  }

  if (document.querySelector('.location-form')) {
    if (document.querySelector('#location_name').value.length === 0) {
      document.getElementById('location_name').classList.remove('valid'); document.getElementById('location_name').classList.add('invalid');
    }
    if (document.querySelector('#location_country').value.length === 0) {
      document.getElementById('location_country').classList.remove('valid'); document.getElementById('location_country').classList.add('invalid');
    }
  }

  if (document.querySelector('.address-form')) {
    if (document.querySelector('#address_street').value.length === 0) {
      document.getElementById('address_street').classList.remove('valid'); document.getElementById('address_street').classList.add('invalid');
    }
    if (document.querySelector('#address_city').value.length === 0) {
      document.getElementById('address_city').classList.remove('valid'); document.getElementById('address_city').classList.add('invalid');
    }
  }

  if (document.querySelector('#new_user')) {
    if (!!document.getElementById('user_email').value) {
      if (document.getElementById('user_email').value.length === 0) {
        document.getElementById('user_email').classList.remove('valid'); document.getElementById('user_email').classList.add('invalid');
      }
    }
  }
  document.getElementById('user_email')?.addEventListener('focusout', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    }
  });

  const ValidateEmail = function(email) {
    const expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    expr.test(email);

    e.target.classList.add('invalid');
    document.getElementById('user_phone')?.addEventListener('change', function() {
      let user_phone = document.getElementById('user_phone').value;
      if (!$.isNumeric(user_phone) || user_phone.length < 7) {
        document.getElementById('user_phone').classList.remove('valid'); document.getElementById('user_phone').classList.add('invalid');
      } else {
        document.getElementById('user_phone').classList.remove('invalid'); document.getElementById('user_phone').classList.add('valid');
      }
    });
    document.getElementById('user_password')?.addEventListener('change', function() {
      const password = document.getElementById('user_password').value;
      if (document.getElementById('user_password_confirmation').value.length === 0) {
        document.getElementById('user_password').classList.remove('valid'); document.getElementById('user_password').classList.add('invalid');
        document.getElementById('user_password_confirmation').classList.remove('valid'); document.getElementById('user_password_confirmation').classList.add('invalid');
      } else {
        const password_confirmation = document.getElementById('user_password_confirmation').value;
        if (password === password_confirmation) {
          document.getElementById('user_password').classList.remove('invalid'); document.getElementById('user_password').classList.add('valid');
          document.getElementById('user_password_confirmation').classList.remove('invalid'); document.getElementById('user_password_confirmation').classList.add('valid');
        } else {
          document.getElementById('user_password').classList.remove('valid'); document.getElementById('user_password').classList.add('invalid');
          document.getElementById('user_password_confirmation').classList.remove('valid'); document.getElementById('user_password_confirmation').classList.add('invalid');
        }
      }
    });

    document.getElementById('user_password_confirmation')?.addEventListener('change', function() {
      const password_confirmation = document.getElementById('user_password_confirmation').value;
      if (document.getElementById('user_password').value.length === 0) {
        document.getElementById('user_password').classList.remove('valid'); document.getElementById('user_password').classList.add('invalid');
        document.getElementById('user_password_confirmation').classList.remove('valid'); document.getElementById('user_password_confirmation').classList.add('invalid');
      } else {
        const password = document.getElementById('user_password').value;
        if (password === password_confirmation) {
          document.getElementById('user_password').classList.remove('invalid'); document.getElementById('user_password').classList.add('valid');
          document.getElementById('user_password_confirmation').classList.remove('invalid'); document.getElementById('user_password_confirmation').classList.add('valid');
        } else {
          document.getElementById('user_password').classList.remove('valid'); document.getElementById('user_password').classList.add('invalid');
          document.getElementById('user_password_confirmation').classList.remove('valid'); document.getElementById('user_password_confirmation').classList.add('invalid');
        }
      }
    });
  };

  documentOn('change', '#location_country,#supplier_country,#employee_country', function() {
    const country = this.value;
    //Add Loader
    fetch(`/manager/country_preferences?country=${ country }`).then(response => response.json().then(data => {
      const locationCurrencyEl = document.getElementById('location_currency');
      if (locationCurrencyEl) {
        locationCurrencyEl.children.map(el => el.remove()); 
        const inputElSiblings = getElementSiblings(locationCurrencyEl, 'input');
        if(inputElSiblings.length > 0) {
          inputElSiblings[0].value = data.currency;
        }
        const ulElSiblings = getElementSiblings(locationCurrencyEl, 'ul');
        if(ulElSiblings.length > 0) {
          ulElSiblings[0].children.map(el => el.remove());
          ulElSiblings[0].insertAdjacentHTML('beforeend', `<li class='active'><span>${ data.currency }</span></li>`);
        }
        locationCurrencyEl.insertAdjacentHTML("beforeend", `<option value=\"${ data.currency }\">${ data.currency }</option>`);
      }

      const selected_time_zone = data.time_zones[0];
      const locationTimeZoneEl = document.getElementById('location_time_zone');
      if (locationTimeZoneEl) {
        locationTimeZoneEl.children.map(el => el.remove()); 
        const inputElSiblings = getElementSiblings(locationTimeZoneEl, 'input');
        if(inputElSiblings.length > 0) {
          inputElSiblings[0].value = selected_time_zone[0];
        }
        const ulElSiblings = getElementSiblings(locationTimeZoneEl, 'ul');
        if(ulElSiblings.length > 0) {
          ulElSiblings[0].children.map(el => el.remove());
          data.time_zones.forEach(time_zone => {
            const time_zone_value = time_zone[0];
            const time_zone_label = time_zone[1];    
            ulElSiblings[0].insertAdjacentHTML('beforeend', `<li class='active'><span>${ time_zone_label }</span></li>`);
            locationTimeZoneEl.insertAdjacentHTML("beforeend", `<option value=\"${ time_zone_value }\">${ time_zone_label }</option>`);
          })
        }
      }
      const locationStateEl = document.getElementById('location_state');
      if (locationStateEl) {
        locationStateEl.children.map(el => el.remove()); 
        const ulElSiblings = getElementSiblings(locationStateEl, 'ul');
        if(ulElSiblings.length > 0) {
          ulElSiblings[0].children.map(el => el.remove());
          data.states.forEach((state, idx) => {
            if (idx === 0) {
              ulElSiblings[0].insertAdjacentHTML('beforeend', `<li class='active'><span>${ state }</span></li>`);
            } else {
              ulElSiblings[0].insertAdjacentHTML('beforeend', `<li><span>${ state }</span></li>`);
            }
            locationStateEl.insertAdjacentHTML("beforeend", `<option value=\"${ state }\">${ state }</option>`);
          });
        }
      }
    }));
  });


  document.getElementById('user_password')?.addEventListener('focusout', function(e) {
    if (this.value !== document.getElementById('user_password_confirmation').value) {
      document.getElementById('user_password_confirmation').classList.remove('valid'); document.getElementById('user_password_confirmation').classList.add('invalid');
    } else {
      document.getElementById('user_password_confirmation').classList.remove('invalid'); document.getElementById('user_password_confirmation').classList.add('valid');
    }
  });
  document.getElementById('user_password_confirmation')?.addEventListener('keyup', function(e) {
    if (document.getElementById('user_password').value !== this.value) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('address_street')?.addEventListener('focusout', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('address_city')?.addEventListener('focusout', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('address_street')?.addEventListener('keyup', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('address_city')?.addEventListener('keyup', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('location_name')?.addEventListener('focusout', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });
  document.getElementById('location_name')?.addEventListener('keyup', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('location_country')?.addEventListener('focusout', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });
  document.getElementById('location_country')?.addEventListener('keyup', function(e) {
    if (e.target.value.length === 0) {
      e.target.classList.remove('valid');
      e.target.classList.add('invalid');
    } else {
      e.target.classList.remove('invalid');
      e.target.classList.add('valid');

    }
  });

  document.getElementById('new_location')?.addEventListener('submit', function(e) {
    if ((document.querySelector('#location_name.invalid')) || (document.querySelector('#location_country.invalid'))) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_address')?.addEventListener('submit', function(e) {
    if (document.querySelector('#address_street.invalid')) {
      new Toast({ text: I18n.t('merchant.valid_street') });
      e.preventDefault();
      return false;
    } else if (document.querySelector('#address_city.invalid')) {
      new Toast({ text: I18n.t('merchant.valid_city') });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('facebook_form')?.addEventListener('submit', function(e) {
    if (document.getElementById('facebook_frequency').value.length > 0) {
      document.getElementById('facebook_frequency').classList.remove('invalid'); document.getElementById('facebook_frequency').classList.add('valid');
    } else {
      document.getElementById('facebook_frequency').classList.remove('valid'); document.getElementById('facebook_frequency').classList.add('invalid');
    }
    if (document.getElementById('new_facebook_post').value.length > 0) {
      document.getElementById('new_facebook_post').classList.remove('invalid'); document.getElementById('new_facebook_post').classList.add('valid');
    } else {
      document.getElementById('new_facebook_post').classList.remove('valid'); document.getElementById('new_facebook_post').classList.add('invalid');
    }


    if (document.querySelector('#facebook_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (document.querySelector('#new_facebook_post.invalid')) {
      new Toast({ text: "Please add Facebook Post" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });


  document.getElementById('twitter_form')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('twitter_frequency').value.length > 0) {
      document.getElementById('twitter_frequency').classList.remove('invalid'); document.getElementById('twitter_frequency').classList.add('valid');
    } else {
      document.getElementById('twitter_frequency').classList.remove('valid'); document.getElementById('twitter_frequency').classList.add('invalid');
    }

    if (document.getElementById('social_twitter_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }

    if (document.querySelector('#twitter_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  //Marketing Campaign Submission Validations    
  document.getElementById('new_sms_orders_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('sms_orders_frequency').value.length > 0) {
      document.getElementById('sms_orders_frequency').classList.remove('invalid'); document.getElementById('sms_orders_frequency').classList.add('valid');
    } else {
      document.getElementById('sms_orders_frequency').classList.remove('valid'); document.getElementById('sms_orders_frequency').classList.add('invalid');
    }

    if (document.getElementById('sms_orders_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#sms_orders_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_sms_likes_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('sms_likes_frequency').value.length > 0) {
      document.getElementById('sms_likes_frequency').classList.remove('invalid'); document.getElementById('sms_likes_frequency').classList.add('valid');
    } else {
      document.getElementById('sms_likes_frequency').classList.remove('valid'); document.getElementById('sms_likes_frequency').classList.add('invalid');
    }

    if (document.getElementById('sms_likes_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#sms_likes_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_email_orders_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('email_orders_frequency').value.length > 0) {
      document.getElementById('email_orders_frequency').classList.remove('invalid'); document.getElementById('email_orders_frequency').classList.add('valid');
    } else {
      document.getElementById('email_orders_frequency').classList.remove('valid'); document.getElementById('email_orders_frequency').classList.add('invalid');
    }

    if (document.getElementById('email_orders_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#email_orders_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_email_likes_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('email_likes_frequency').value.length > 0) {
      document.getElementById('email_likes_frequency').classList.remove('invalid'); document.getElementById('email_likes_frequency').classList.add('valid');
    } else {
      document.getElementById('email_likes_frequency').classList.remove('valid'); document.getElementById('email_likes_frequency').classList.add('invalid');
    }

    if (document.getElementById('email_likes_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#email_likes_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_push_orders_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('push_orders_frequency').value.length > 0) {
      document.getElementById('push_orders_frequency').classList.remove('invalid'); document.getElementById('push_orders_frequency').classList.add('valid');
    } else {
      document.getElementById('push_orders_frequency').classList.remove('valid'); document.getElementById('push_orders_frequency').classList.add('invalid');
    }

    if (document.getElementById('push_orders_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#push_orders_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  document.getElementById('new_push_likes_campaign')?.addEventListener('submit', function(e) {
    let empty_items;
    if (document.getElementById('push_likes_frequency').value.length > 0) {
      document.getElementById('push_likes_frequency').classList.remove('invalid'); document.getElementById('push_likes_frequency').classList.add('valid');
    } else {
      document.getElementById('push_likes_frequency').classList.remove('valid'); document.getElementById('push_likes_frequency').classList.add('invalid');
    }

    if (document.getElementById('push_likes_campaign_item_list').value.length > 0) {
      empty_items = false;
    } else {
      empty_items = true;
    }
    if (document.querySelector('#push_likes_frequency.invalid')) {
      new Toast({ text: "Please add frequency for no of posts" });
      e.preventDefault();
      return false;
    } else if (empty_items) {
      new Toast({ text: "Please add item lists" });
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  });

  let fbk_item_counter = 0;
  const tag_label = '';
  let length = document.querySelectorAll('.social_campaign_facebook_item_list').length;
  while (fbk_item_counter < length) {
    $('#social_campaign_fbk_item_' + fbk_item_counter).tagit({
      fieldName: "social_campaign[item_list]",
      singleField: true,
      allowSpaces: true,
      autocomplete: {
        minLength: 2,
        source: document.querySelector('#social_campaign_fbk_item_' + fbk_item_counter).dataset.source
      },
      placeholderText: 'Type in items'
    });
    fbk_item_counter = fbk_item_counter + 1;
  }

  let fbk_place_counter = 0;
  ({
    length
  } = $('.social_campaign_facebook_place_list'));
  while (fbk_place_counter < length) {
    $('#social_campaign_facebook_place_' + fbk_place_counter).tagit({
      fieldName: "social_campaign[place_list]",
      singleField: true,
      allowSpaces: true,
      autocomplete: {
        minLength: 2,
        source: document.querySelector('#social_campaign_fbk_place_' + fbk_place_counter).getAttribute('data_autocomplete_source')
      },
      placeholderText: 'Type in items'
    });
    fbk_place_counter = fbk_place_counter + 1;
  }

  let twitter_item_counter = 0;
  ({
    length
  } = $('.social_campaign_twitter_item_list'));
  while (twitter_item_counter < length) {
    $('#social_campaign_twitter_item_' + twitter_item_counter).tagit({
      fieldName: "social_campaign[item_list]",
      singleField: true,
      allowSpaces: true,
      autocomplete: {
        minLength: 2,
        source: document.querySelector('#social_campaign_twitter_item_' + twitter_item_counter).dataset.source
      },
      placeholderText: 'Type in items',
      beforeTagRemoved(e, ui) {
        let tag_index;
        const ul = document.getElementById(ui.tag.parent().getAttribute('id'));
        const items = ul.getElementsByTagName("li");
        let count = 0;
        while (count < items.length) {
          const str = items[count].textContent.slice(0, -1);
          if (ui.tagLabel === str) {
            tag_index = count;
          }
          count = count + 1;
        }
        const div_id = ui.tag.parent().getAttribute('id').split("_")[4];
        const arra = document.getElementsByName("social_campaign[item_list]")[div_id].value.split(",");
        arra.splice(tag_index, 1);
        return document.getElementsByName("social_campaign[item_list]")[div_id].value = arra.toString();
      }
    });
    twitter_item_counter = twitter_item_counter + 1;
  }

  let twitter_place_counter = 0;
  ({
    length
  } = $('.social_campaign_twitter_place_list'));
  while (twitter_place_counter < length) {
    $('#social_campaign_facebook_place_' + twitter_place_counter).tagit({
      fieldName: "social_campaign[place_list]",
      singleField: true,
      allowSpaces: true,
      autocomplete: {
        minLength: 2,
        source: document.querySelector('#social_campaign_twitter_place_' + twitter_place_counter).getAttribute('data_autocomplete_source')
      },
      placeholderText: 'Type in items'
    });
    twitter_place_counter = twitter_place_counter + 1;
  }

  document.getElementById('new_fbk_social_campaign')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_fbk_new').style.display = '';
    document.getElementById('new_fbk_social_campaign').style.display = 'none';
  });
  document.getElementById('cancel_fbk_campaign_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_fbk_social_campaign').style.display = '';
    document.getElementById('social_campaign_fbk_new').style.display = 'none';
  });
  document.getElementById('new_twitter_social_campaign')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_twitter_new').style.display = '';
    document.getElementById('new_twitter_social_campaign').style.display = 'none';
  });
  document.getElementById('cancel_twitter_campaign_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_twitter_social_campaign').style.display = '';
    document.getElementById('social_campaign_twitter_new').style.display = 'none';
  });

  document.getElementById('blog_signup_btn')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('signin_form').style.display = 'none';
    document.getElementById('signup_form').style.display = '';
  });
  document.getElementById('blog_signin_btn')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('signup_form').style.display = 'none';
    document.getElementById('signin_form').style.display = '';
  });

  documentOn('change', '#location_state', function() {
    let country;
    const state = this.value;
    country = document.getElementById('location_country').value;
  });

  documentOn('change', '.location_country', function(e) {
    let country_iso = e.currentTarget.selectedOptions[0].value;
    let url = `/api/countries/${country_iso}/states?invert=true`; 
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => response.json()).then(data => {
      let state_select = document.querySelector('.country-states').querySelector('.state_select');
      state_select.innerHTML = '';
      let option = document.createElement("option");
      option.label = 'State';
      state_select.add(option);
      const keys = Object.keys(data);
      keys.forEach(function(key, value) {
        let option = document.createElement("option");
        option.label = key;
        option.value = value;
        state_select.add(option);
      });
      FormSelect.init([state_select]);
    });
  });

  return documentOn('click', '#invoices .pagination a', function() {
    getScript(this.href);
    return false;
  });
});
