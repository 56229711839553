export default class EposTray {
  static init = '\x1B\x40';
  static standard_font_size =  '\x1D\x21\x00';
  static bold_on = '\x1b\x45\x01';
  static bold_off = '\x1b\x45\x00';
  static double_font_size = '\x1D\x21\x11';
  static small_text = '\x1B\x4D\x31';
  static normal_text = '\x1B\x4D\x30';
  static center_align = '\x1B\x61\x31';
  static right_align = '\x1B\x61\x32';
  static cut_paper = '\x1D' + '\x56'  + '\x30'; //'\x1B\x69';
  static open_drawer = '\x10\x14\x01\x00\x05';// Generate Pulse to kick-out cash drawer**

  constructor(config) {
    this.config = config;
    this.data = [EposTray.init];
    this.isText = false;
  }

  reset = () => {
    this.data = [EposTray.init];
    return this;
  }

  cutPaper = () => {
    this.data.push(EposTray.cut_paper);
    this.isText = true;
    return this;
  }

  openDrawer = () => {
    this.data.push(EposTray.open_drawer);
    this.isText = false;
    return this;
  }

  rightAlign = () => {
    this.data.push(EposTray.right_align);
    this.isText = false;
    return this;
  }

  centerAlign = () => {
    this.data.push(EposTray.center_align);
    this.isText = false;
    return this;
  }

  smallText = () => {
    this.data.push(EposTray.small_text);
    this.isText = false;
    return this;
  }

  normalText = () => {
    this.data.push(EposTray.normal_text);
    this.isText = false;
    return this;
  }

  boldText = () => {
    this.data.push(EposCordova.double_font_size);
    this.isText = false;
    return this;
  }

  boldOn = () => {
    this.data.push(EposTray.bold_on);
    this.isText = false;
    return this;
  }

  boldOf = () => {
    this.data.push(EposTray.bold_off);
    this.isText = false;
    return this;
  }

  standardFontSize = () => {
    this.data.push(EposTray.standard_font_size);
    this.isText = false;
    return this;
  }

  doubleFontSize = () => {
    this.data.push(EposTray.double_font_size);
    this.isText = false;
    return this;
  }

  text = (txt) => {
    if (this.isText) {
      this.data[this.data.length - 1] += txt;
    } else {
      this.data.push(txt);
      this.isText = true;
    }
    return this;
  }

  lineBreak = () => {
    this.text("\n");
    return this;
  }

  fifteenLineBreak =() =>{
    this.text("\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n");
    return this; 
  }

  output = () => {
    return this.data;
  }

  printLogo = (locationLogo) => {
    if (locationLogo !== 'none') {
      this.isText = false
      this.data.push({
        type: 'raw',
        format: 'image',
        data: locationLogo, options: { language: "ESCPOS", dotDensity: 'double' }
      })
    }
  }
};
