import $ from "../shared/jquery-global";
import { documentOn } from "../shared/jquery-alternative";
import { Modal } from '@materializecss/materialize';

const setupWeekday = function(weekday) {
  if (!document.querySelector(`#button-split-${ weekday }`)) return;
  //Continue Service- Sunday
  document.querySelector(`#button-split-${ weekday }`)?.addEventListener('click', function() {
    document.querySelector(`#splitable-${ weekday }`).style.display = '';
    document.querySelector(`#button-split-${ weekday }`).style.display = 'none';
    document.querySelector(`#button-continue-${ weekday }`).style.display = '';
    document.querySelector(`#timepicker-split-start-${ weekday }`).removeAttribute('disabled');
    document.querySelector(`#timepicker-split-end-${ weekday }`).removeAttribute('disabled');
  });
  document.querySelector(`#button-continue-${ weekday }`)?.addEventListener('click', function() {
    document.querySelector(`#splitable-${ weekday }`).style.display = 'none';
    document.querySelector(`#button-split-${ weekday }`).style.display = '';
    document.querySelector(`#button-continue-${ weekday }`).style.display = 'none';
    document.querySelector(`#timepicker-split-start-${ weekday }`).setAttribute('disabled', true);
    document.querySelector(`#timepicker-split-end-${ weekday }`).setAttribute('disabled', true);
  });


  //Sunday checkBox
  if (document.querySelector(`#schedules_${ weekday }_selected`).checked) {
    document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.removeAttribute('disabled'));
    document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.removeAttribute('disabled'));
    document.querySelector(`#timepicker-split-start-${ weekday }`).removeAttribute('disabled');
    document.querySelector(`#timepicker-split-end-${ weekday }`).removeAttribute('disabled');
    document.querySelector(`#button-split-${ weekday }`).style.display = '';
  } else {
    document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.setAttribute('disabled', true));
    document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.setAttribute('disabled', true));
    document.querySelector(`#timepicker-split-start-${ weekday }`).setAttribute('disabled', true);
    document.querySelector(`#timepicker-split-end-${ weekday }`).setAttribute('disabled', true);
    document.querySelector(`#button-split-${ weekday }`).style.display = 'none';
  }


  //Sunday On Click
  document.querySelector(`#schedules_${ weekday }_selected`)?.addEventListener('click', function(e) {
    if (e.target.checked) {
      document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.removeAttribute('disabled'));
      document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.removeAttribute('disabled'));
      document.querySelector(`#timepicker-split-start-${ weekday }`).removeAttribute('disabled');
      document.querySelector(`#timepicker-split-end-${ weekday }`).removeAttribute('disabled');
      document.querySelector(`#button-split-${ weekday }`).style.display = '';
    } else {
      document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.classList.remove('invalid'));
      document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.classList.add('valid'));

      document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.classList.remove('invalid'));
      document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.classList.add('valid'));

      document.querySelectorAll(`.timepicker-start-${ weekday }`).forEach(el => el.setAttribute('disabled', true));
      document.querySelectorAll(`.timepicker-end-${ weekday }`).forEach(el => el.setAttribute('disabled', true));
      document.querySelector(`#timepicker-split-start-${ weekday }`).setAttribute('disabled', true);
      document.querySelector(`#timepicker-split-end-${ weekday }`).setAttribute('disabled', true);
      document.querySelector(`#button-split-${ weekday }`).style.display = 'none';
      document.querySelector(`#button-continue-${ weekday }`).style.display = 'none';
      document.querySelector(`#splitable-${ weekday }`).style.display = 'none';
    }
  });

  if ((document.querySelector(`#schedules_${ weekday }_selected`).checked) && (document.querySelector(`#button-continue-${ weekday }`).style.display !== 'none')) {
    document.querySelector(`#button-split-${ weekday }`).style.display = 'none';
  }
};

export var initScheduleView = function() {

  let validateSchedules;
  let counter_check = 0;
  let check_recurring = 0;
  document.querySelector('#recurring-schedule')?.addEventListener('click', function() {
    check_recurring = 1;
    document.querySelector('.simple-schedule').style.disabled = 'none';
    document.querySelector('.ranges').style.display = '';
    document.querySelector('#timepicker-start-time').classList.remove('valid');
    document.querySelector('#timepicker-start-time').classList.add('invalid');

    document.querySelector('#timepicker-end-time').classList.remove('valid');
    document.querySelector('#timepicker-start-time').classList.add('invalid');
  });


  document.querySelector('#simple-schedule')?.addEventListener('click', function() {
    check_recurring = 0;
    document.querySelector('.simple-schedule').style.display = '';
    document.querySelector('.ranges').style.display = 'none';
    document.querySelector('#timepicker-start-time').classList.remove('invalid');
    document.querySelector('#timepicker-start-time').classList.add('valid');
    document.querySelector('#timepicker-end-time').classList.remove('invalid');
    document.querySelector('#timepicker-end-time').classList.add('valid');
  });

  const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  for (var weekday of weekdays) {
    //Recurring Sunday
    document.querySelector(`#recurring-${ weekday }`)?.addEventListener('click', function(e) {
      if (e.target.checked) {
        counter_check = counter_check + 1;
        if (counter_check === 2) {
          for (let weekday2 of weekdays) {
            if (weekday2 !== weekday) {
              if (document.querySelector(`recurring-${ weekday2 }`).checked) {
                document.querySelector(`recurring-${ weekday2 }`).setAttribute('disabled', true);
              }
            }
          }
        }
      } else {
        counter_check = counter_check - 1;
        for (let weekday2 of weekdays) {
          if (weekday2 !== weekday) {
            if (document.querySelector(`#recurring-${ weekday2 }`).disabled) {
              document.querySelector(`#recurring-${ weekday2 }`).disabled = false;
            }
          }
        }
      }
    });

    setupWeekday(weekday);
  }


  documentOn('submit', 'form#location_schedule_form', function(e) {
    e.preventDefault();
    const me = this;
    if (validateSchedules(e)) {
      const formData = new FormData(this);
      fetch(this.action, {
        method: "POST",
        headers: {
          "Accept": "application/json" 
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if (!!data && !!data.errors && (data.errors.length > 0)) {
          alert(data.errors.join("\n"));
        } else {
          const elem = me.closest('.modal');
          // const instance = Modal.getInstance(elem);
          // instance.close();
          elem.hidePopover();
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } else {
      alert("Add schedule time in selected checkbox");
    }
  });

  validateSchedules = function(e) {
    let allOk = true;
    if (check_recurring === 1) {
      if (document.querySelector('#timepicker-start-time').value.length === 0) {
        document.querySelector('#timepicker-start-time').classList.remove('valid');
        document.querySelector('#timepicker-start-time').classList.add('invalid');
        allOk = false;
      } else {
        document.querySelector('#timepicker-start-time').classList.remove('invalid');
        document.querySelector('#timepicker-start-time').classList.add('valid');
      }
      if (document.getElementById('timepicker-end-time').value.length === 0) {
        document.querySelector('#timepicker-end-time').classList.remove('valid');
        document.querySelector('#timepicker-end-time').classList.add('invalid');
        allOk = false;
      } else {
        document.querySelector('#timepicker-end-time').classList.remove('invalid');
        document.querySelector('#timepicker-end-time').classList.add('valid');
      }
    } else {
      for (var weekday of weekdays) {
        if (document.querySelector(`#schedules_${ weekday }_selected`).checked) {
          if (document.querySelector(`#timepicker-start-${ weekday }`).value.length === 0) {
            document.querySelector(`#timepicker-start-${ weekday }`).classList.remove('valid');
            document.querySelector(`#timepicker-start-${ weekday }`).classList.add('invalid');
            allOk = false;
          } else {
            document.querySelector(`#timepicker-start-${ weekday }`).classList.remove('invalid');
            document.querySelector(`#timepicker-start-${ weekday }`).classList.add('valid');
          }
          if (document.querySelector(`#timepicker-end-${ weekday }`).value.length === 0) {
            document.querySelector(`#timepicker-end-${ weekday }`).classList.remove('valid');
            document.querySelector(`#timepicker-end-${ weekday }`).classList.add('invalid');
            allOk = false;
          } else {
            document.querySelector(`#timepicker-end-${ weekday }`).classList.remove('invalid');
            document.querySelector(`#timepicker-end-${ weekday }`).classList.add('valid');
          }
        }
      }
    }

    return allOk;
  };
};

