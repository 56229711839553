import { enableFirebaseNotifications } from "./firebase-base";
import { I18nRails } from "../shared/rails-i18n-js";
import appIcon58 from "../images/app-icon-58.png";

export function notify(title, message, options = {}) {
  if (options == null) { options = {}; }
  if (!!window.Notification) {
    let notification;
    if (!!options.icon) { options.icon = appIcon58; }
    options.body = message;
    if (Notification.permission === Notification.PERMISSION_GRANTED) {
      notification = new Notification(title, options);

      notification.onclick = function(x) {
        if (!!options.link) { window.location.href = options.link; }
        window.focus();
      };
    } else {
      // require notification permission
      if (!!options.icon) { options.icon = appIcon58; }
      options.body = message;
      Notification.requestPermission().then(function() {
        notification = new Notification(title, options);
        notification.onclick = function(x) {
          if (!!options.link) { window.location.href = options.link; }
          window.focus();
        };
      });
    }
  }
}

export function displayNotification(notification) {
  if (!!notification.body) {
    const title = !!notification.title ? notification.title : '';
    const link = !!notification.link ? notification.link : '/manager/pos';
    notify(title, notification.body, { link });
  }
}

export function processNotificationNonReact(payload) {
  if (!!payload.data && !!payload.data.order_id || !!payload.order_id) {
    if (!!payload.notification) {
      displayNotification(payload.notification);
    }
    // addNewOrder(payload.data.order_id, payload.notification);
  } else if (!!payload.data && payload.data.type === 'notification') {
    notify(payload.data.title, payload.data.message, { link: payload.data.link });
  }
  else {
    console.log(`no payload data and order_id: ${ JSON.stringify(payload) }`);
  }
}

export function onBackgroundMessageListener(registration, payload) {
  console.log('backgroundNotification received');
  // const notificationTitle = 'Background Message Title';
  // const notificationOptions = {
  //   body: 'Background Message body.',
  //   icon: '/firebase-logo.png'
  // };

  // self.registration.showNotification(notificationTitle,
  //   notificationOptions);

  processNotification(payload);
}
export function processNotification(payload) {
  console.log("payload received: ", payload);
  if (document.querySelector('react-view')) {
    //trigger window notification for react
    let event = new Event('notification_received');
    if (window.dispatchEvent(event, { payload })) {
      // event.preventDefault was not called, so we will
      // do the standard processNotificationNonReact
      processNotificationNonReact(payload);
    }
  } else {
    processNotificationNonReact(payload);
  }
}

function notifyNewOrder(order) {
  const received_at = new Date(order.received_at);
  const notification_message = I18nRails.t("notifiers.order_created", {
    order_state: order.state,
    at: I18nRails.l('time.formats.short_time', received_at),
    order_id: order.id,
    price: I18nRails.toCurrency(order.price, { unit: order.currency_symbol }),
    user_ref: (!!order.user.phone ? order.user.phone : order.user.email),
    loc: order.location.name
  }
  );

  notify(I18nRails.t("order_dock.order.notification.new_order_title"), notification_message);
}

function processOrder(order) {
  if (order.is_incoming) {
    let elem;
    if (elem = document.querySelector('incoming-orders #audio')) {
      elem.innerHTML = `<audio id='audio-${ order.id }' loop='loop' autoplay><source src='/sounds/order_incoming.mp3'></audio>`;
    }

    document.querySelector('.volume_up').style.display = '';
    document.querySelector('.volume_off').style.display = 'none';
  } else { //in-progress
    if (order.is_completed) {
      return;
    }
  }
  if (!order.received_at) {
    order.received_at = new Date(order.received_at);
  }
}

function addNewOrder(order_id, notification) {
  let xmlhttp = new XMLHttpRequest();
  xmlhttp.open("GET", `/manager/orders/${ order_id }.json`, true);
  xmlhttp.onreadystatechange = function() {
    if (xmlhttp.readyState == XMLHttpRequest.DONE) {
      if (xmlhttp.status == 200) {
        // processOrder(order);
        if (!!notification) {
          displayNotification(notification);
        } else {
          notifyNewOrder(order);
        }
      } else {
        alert("Failed to fetch order");
      }
    };
    xmlhttp.send();
  };
}
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    enableFirebaseNotifications(processNotification);
  });

  document.addEventListener('DOMContentLoaded', function() {
    // setTimeout(() => {
    //   processNotification({ order_id: 'GT210813141050008-2' });
    // }, 5000);
    if (document.querySelector('.volume_up')) {
      document.querySelector('.volume_up').addEventListener('click', (e) => {
        if (document.querySelector('.incoming-orders #audio')) {
          let audioEls = document.querySelector('.incoming-orders #audio').querySelectorAll('audio');
          audioEls.forEach((audioElem) => {
            if (audioElem.parentNode !== null) {
              audioElem.parentNode.removeChild(el);
            }
          });
        };
        document.querySelector('.volume_off').style.display = '';
      });
    }
  });
}

