export default class EposCordova {
  static init = '1B40';
  static center_align = '1B6131';
  static double_font_size = '1D2111';
  static normal_text = '1B4D30';
  static standard_font_size = '1D2100';
  static small_text = '1B4D31';
  static right_align = '1B6132';
  static bold_text = '1B450D';
  static bold_text_off = '1B450A';

  constructor(config) {
    this.config = config;
    this.data = [];
    this.isText = false;
  }

  reset() {
    this.data = [];
    return this;
  }

  cutPaper() {
    this.data.push({ 
      type: 'cut-paper', 
      text: ''
    });
    return this;
  }

  // TODO unimplemented
  openDrawer() {
    this.data.push({ 
      type: 'hex',
      text: ''
    }); //TODO unimplemented
    return this;
  }

  rightAlign() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.right_align
    });
    this.isText = false;
    return this;
  }

  centerAlign() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.center_align
    });
    return this;
  }

  smallText() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.small_text
    });
    return this;
  }

  normalText() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.normal_text
    });
    return this;
  }

  boldText() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.bold_text
    });
    return this;
  }

  boldTextOff() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.bold_text_off
    });
    return this;
  }

  standardFontSize() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.standard_font_size
    });
    return this;
  }

  doubleFontSize() {
    this.data.push({ 
      type: 'hex',
      text: EposCordova.double_font_size
    });
    return this;
  }

  text(txt) {
    if (this.data[this.data.length - 1].type === 'print') {
      this.data[this.data.length - 1].text += txt;
    } else {
      this.data.push({ 
        type: 'print',
        text: txt
      });
    }
    return this;
  }

  lineBreak() {
    return this.text("\n");
  }
  
  output() {
    return this.data;
  }
};
