document.addEventListener('DOMContentLoaded', function() {
  let order_element = document.getElementById("order_location");

  if (order_element) order_element.addEventListener('change', function() {
    myfunction(this, 'order_notes', '#order_notes_selected_checkboxes', '#printing_options_block input[type="checkbox"]:checked');
  });

});


function myfunction(x, a, y, z) {
  let _this_ = x;
  if (!_this_.checked) {
    _this_.closest('form').querySelector(y).children().remove();
  } else {
    _this_.parents('form').querySelector(z).toArray().forEach((checkbox_element) => {
      let checkbox_text = $(checkbox_element).next().text();
      if (checkbox_text == "Submit Button") {
        checkbox_text = "submit_btn";
      }
      _this_.closest('form').querySelector(y).append(
        `
          <p>
            <label>
              <input name="location[${ a }_${ checkbox_text.toLowerCase() }]" type="hidden" value="0">
              <input type="checkbox" value="1" name="location[${ a }_${ checkbox_text.toLowerCase() }]" id="order_location_${ document.querySelector(checkbox_element).getAttribute('id') }">
              <span> ${ $(checkbox_element).next().text() }</span>
            </label>
          </p>
          `
      );
    });
  }
}