
import { documentOn } from "../shared/jquery-alternative";
document.addEventListener('DOMContentLoaded', function() {
  documentOn('change', '.announcement-rule-type', function(e) {
    const partial_name = (e.currentTarget.selectedOptions[0].label.split(/(?=[A-Z])/).join('_').toLowerCase());
    if (partial_name === 'geo_radius') {
      e.currentTarget.closest('.announcement-fields-type').querySelector('.geo_radius').classList.remove('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.no_order').classList.add('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.order').classList.add('hidden');
    }
    else if (partial_name === 'no_order') {
      e.currentTarget.closest('.announcement-fields-type').querySelector('.geo_radius').classList.add('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.no_order').classList.remove('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.order').classList.add('hidden');
    }
    else if (partial_name === 'order') {
      e.currentTarget.closest('.announcement-fields-type').querySelector('.geo_radius').classList.add('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.no_order').classList.add('hidden');
      e.currentTarget.closest('.announcement-fields-type').querySelector('.order').classList.remove('hidden');
    }
  });
});
