document.addEventListener('DOMContentLoaded', function() {
let serviceModeElem = document.getElementById("service_mode");
  if (!!serviceModeElem) {
    serviceModeElem.addEventListener('change', function(e) {
      let serviceMode = e.currentTarget.selectedOptions[0].label;
      if (serviceMode == 'Default Geo Polygons') {
        document.getElementsByClassName('default_geo_polygons')[0].classList.remove('hide-mode');
        document.getElementsByClassName('default_max_distance')[0].classList.add('hide-mode');
        document.getElementsByClassName('default_zip_codes')[0].classList.add('hide-mode');
      }
      else if (serviceMode == 'Default Max Distance') {
        document.getElementsByClassName('default_max_distance')[0].classList.remove('hide-mode');
        document.getElementsByClassName('default_geo_polygons')[0].classList.add('hide-mode');
        document.getElementsByClassName('default_zip_codes')[0].classList.add('hide-mode');
      }
      else if (serviceMode == 'Default Zip Codes') {
        document.getElementsByClassName('default_zip_codes')[0].classList.remove('hide-mode');
        document.getElementsByClassName('default_geo_polygons')[0].classList.add('hide-mode');
        document.getElementsByClassName('default_max_distance')[0].classList.add('hide-mode');
      }
    });
  }

  $('#rider_profile_service_area').on('select2:select', function (e) {
    document.getElementsByClassName('service_mode_fields')[0].classList.remove('hide-mode');
  });
});