import $ from "./jquery-global";

document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('#blog-like')?.addEventListener('click', function(event) {
    event.preventDefault();
    const blog_id = event.currentTarget.dataset.blogId;
    const auth_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    event.currentTarget.style.pointerEvents = "none";
    $.post(`/blogs/${ blog_id }/favorite`, { authenticity_token: auth_token }, function(data, status, xhr) {
      if (xhr.status === 201) {
        event.currentTarget.style.pointerEvents = "all";
        event.currentTarget.querySelector("span").innerHtml = " Liked";
      } else if (xhr.status === 202) {
        event.currentTarget.style.pointerEvents = "all";
        event.currentTarget.querySelector("span").innerHtml = " Like";
      }
    });
  });
});
