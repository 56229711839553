// import M from "../shared/materialize-global";
import { Toast } from "@materializecss/materialize";
document.addEventListener('DOMContentLoaded', function() {
  const show_error = function(message) {
    new Toast({ text: message });
    return false;
  };

  document.getElementById('submit_stripe_form')?.addEventListener('click', function(event) {
    document.querySelector('.err-msgses-red').style.display = 'none';

    if (document.getElementById('card_number').value === "") {
      document.querySelector('.error-msg1').style.display = 'block';
    } else if (document.getElementById('card_number').value.match(/^4[0-9]{12}(?:[0-9]{3})?$/)) {
      document.querySelector('.error-msg1').style.display = 'none';
      document.querySelector('.error-msg2').style.display = 'none';
    } else if (document.getElementById('card_number').value.match(/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/)) {
      document.querySelector('.error-msg1').style.display = 'none';
      document.querySelector('.error-msg2').style.display = 'none';
    } else {
      document.querySelector('.error-msg2').style.display = 'block';
      event.preventDefault();
    }

    if (document.getElementById('card_verification').value === "") {
      document.querySelector(".error-msg3").style.display = 'block';
    } else if (document.getElementById('card_verification').value.match(/^[0-9]{3}$/)) {
      document.querySelector(".error-msg3").style.display = 'none';
    } else {
      document.querySelector(".error-msg4").style.display = 'block';
      event.preventDefault();
    }

    const rec_year = new Date().getFullYear();
    const rec_month = new Date().getMonth();

    if (document.querySelector('[data-stripe=exp-year]').value > rec_year) {
      document.querySelector('.error-msg5').style.display = 'none';
    } else if ((document.querySelector('[data-stripe=exp-year]').value === rec_year) && (document.querySelector('[data-stripe=exp-month]').value >= rec_month)) {
      document.querySelector('.error-msg5').style.display = 'none';
    } else {
      document.querySelector('.error-msg5').style.display = 'block';
      event.preventDefault();
    }

    document.querySelector('#stripe_form').submit();
  });

  const stripeResponseHandler = function(status, response) {
    const form = document.querySelector('#stripe_form');
    if (response.error) {
      if (!!response.error.code) {
        const str = "stripe.errors." + response.error.code;
        console.log(str);
        const msg = I18n.t(str);
        show_error(msg);
      } else {
        if (response.error.message === "Could not find payment information") {
          show_error(I18n.t('merchant.no_card'));
        } else {
          show_error(response.error.message);
        }
      }
      form.querySelector('input[type=submit]').disabled = false;
    } else {
      const token = response.id;
      form.append(`<input type="hidden" name="subscription[card_token]" value=${ token } />`);
      document.querySelectorAll('.ccard-inputs').forEach(el => el.stye.display = 'none');
      document.querySelector('[data-stripe=number]').remove();
      document.querySelector('[data-stripe=cvc]').remove();
      document.querySelector('[data-stripe=exp-year]').remove();
      document.querySelector('[data-stripe=exp-month]').remove();
      form.submit();
    }
    return false;
  };

  document.querySelector('#stripe_form')?.addEventListener('submit', function(e) {
    const form = e.currentTarget;
    form.querySelector('input[type=submit]').disabled = true;
    Stripe.setPublishableKey(form.getAttribute('data-publishable-key'));
    Stripe.card.createToken(form, stripeResponseHandler);
    e.preventDefault();
    return false;
  });
});
