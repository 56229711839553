export function loadSelect2() {
  return Promise.all([
    // Import TinyMCE
    import(/* webpackChunkName: "select2" */ 'select2/dist/js/select2.full'),
    import(/* webpackChunkName: "select2" */ 'select2/dist/css/select2'),
  ]).then(imports => {
    return imports[0].default;
  });
}

export function lazySelect2(jQuery, querySelectorString) {
  if (document.querySelector(querySelectorString)) {
    loadSelect2().then(() => {
      jQuery(querySelectorString).select2();
    });
  }
}

export function loadTagIt() {
  return Promise.all([
    import(/* webpackChunkName: "tag-it" */ "jquery-ui-dist/jquery-ui"),

    import(/* webpackChunkName: "tag-it" */ "tag-it"),
    import(/* webpackChunkName: "tag-it" */ "jquery-ui-dist/jquery-ui.css"),
    import(
      /* webpackChunkName: "tag-it" */ "../../../vendor/assets/stylesheets/jquery.tagit.css"
    ),
  ]).then((imports) => {
    return imports[0].default;
  });
}
