
export default {
  apiKey: process.env.FIREBASE_API_KEY_WEB,
  authDomain: "foodnerd-79f40.firebaseapp.com",
  databaseURL: "https://foodnerd-79f40.firebaseio.com",
  projectId: "foodnerd-79f40",
  storageBucket: "foodnerd-79f40.appspot.com",
  messagingSenderId: "359007590162",
  appId: "1:359007590162:web:d3fc9b7f8aa17a5f"
};
