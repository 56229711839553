import { documentOn, showElements, hideElements } from "../shared/jquery-alternative";
document.addEventListener('DOMContentLoaded', function() {
  documentOn('change', '.employee .commission', function(e) {
    var commission = e.currentTarget.selectedOptions[0].label;
    if (commission == 'Percentage Commission') {
      let divEl = document.createElement('div');
      divEl.classList.add('row');
      document.querySelector('.percentage_commission').parentNode.parentNode.insertBefore(divEl, document.querySelector('.percentage_commission').parentNode);
      divEl = document.createElement('div');
      divEl.classList.add('row');

      document.querySelector('.state').insertBefore(divEl, document.querySelector(".state"));
      showElements(document.querySelectorAll('.percentage_commission'));
      hideElements(document.querySelectorAll('.flat_commission'));
    }
    else if (commission == 'Flat Commission') {
      document.querySelector('.percentage_commission').parentNode.previousSibling.classList.remove("row");
      let divEl = document.createElement('div');
      divEl.classList.add('row');

      document.querySelector('.state').insertBefore(divEl, document.querySelector(".state"));
      showElements(document.querySelectorAll('.flat_commission'));
      hideElements(document.querySelectorAll('.percentage_commission'));
    }
  });

  documentOn('click', '.print_reports #print_commission_report', function() {
    var printContents = document.querySelector('.print_report').innerHTML;
    var mywindow = window.open('', 'PRINT', 'height=800,width=900');
    mywindow.document.write(`<html><body>${ printContents }</body></html>`);
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  });
});