import { documentOn } from "../shared/jquery-alternative";
document.addEventListener('DOMContentLoaded', function() {
  documentOn('click', '.print_reports #print_due_report', function() {
    var printContents = document.querySelector('.print_report').innerHTML;
    var mywindow = window.open('', 'PRINT', 'height=800,width=900');
    mywindow.document.write(`<html><body>${ printContents }</body></html>`);
    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.close();
  });
});