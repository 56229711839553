import { elementOn } from '../shared/jquery-alternative';
document.addEventListener('DOMContentLoaded', function() {
  if (document.getElementById("hosted_data_pos_category_block_settings_attributes_block_use_image")) {

    elementOn(document.body, 'change', '#hosted_data_pos_category_block_settings_attributes_block_use_image', function(event) {
      event.preventDefault();

      if (document.getElementById("hosted_data_pos_category_block_settings_attributes_block_use_image").checked) {
        document.getElementById("hosted_data_pos_category_block_settings_attributes_block_bg_color").disabled = true;
      }
      else {
        document.getElementById("hosted_data_pos_category_block_settings_attributes_block_bg_color").disabled = false;
      }
    });

    elementOn(document.body, 'change', '#hosted_data_pos_menu_items_settings_attributes_menu_use_image', function(event) {
      event.preventDefault();

      if (document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_use_image").checked) {
        document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_bg_color").disabled = true;
      }
      else {
        document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_bg_color").disabled = false;
      }
    });
  }
  function disable_form() {
    let hosted_data_settings_element = document.getElementById("hosted_data_default_settings");
    if (hosted_data_settings_element && hosted_data_settings_element.checked == true) {
      var fields = document.getElementsByClassName("pos-field");
      for (var i = 0; i < fields.length; i++) {
        fields[i].disabled = true;
        if (document.getElementsByClassName('light-color')[i]) {
          document.getElementsByClassName('light-color')[i].classList.remove('opacity-up');
          document.getElementsByClassName('light-color')[i].classList.add('opacity-down');
        }
      };
    }
    else {
      var fields = document.getElementsByClassName("pos-field");
      for (var i = 0; i < fields.length; i++) {
        fields[i].disabled = false;
        if (document.getElementsByClassName('light-color')[i]) {
          document.getElementsByClassName('light-color')[i].classList.remove('opacity-down');
          document.getElementsByClassName('light-color')[i].classList.add('opacity-up');
        }
      };
    }
  }
  if (document.getElementById("hosted_data_default_settings")) {
    disable_form();
    document.getElementById("hosted_data_default_settings")?.addEventListener('change', function() {
      disable_form();
    });
  }

  if (document.getElementById("hosted_data_pos_category_block_settings_attributes_block_use_image")) {

    if (document.getElementById("hosted_data_pos_category_block_settings_attributes_block_use_image").checked) {
      document.getElementById("hosted_data_pos_category_block_settings_attributes_block_bg_color").disabled = true;
    }
    else {
      document.getElementById("hosted_data_pos_category_block_settings_attributes_block_bg_color").disabled = false;
    }

    if (document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_use_image").checked) {
      document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_bg_color").disabled = true;
    }
    else {
      document.getElementById("hosted_data_pos_menu_items_settings_attributes_menu_bg_color").disabled = false;
    }

  }
});



