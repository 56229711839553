import $ from "../shared/jquery-global";
document.addEventListener('DOMContentLoaded', function() {
  let tax_number = document.getElementById("tax_number_tax_settings");

  if (tax_number) tax_number.addEventListener('change', function(e) {
    if (e.currentTarget.checked) {
      e.currentTarget.closest('form').querySelector('#tax_number_selected_checkboxes,#tax_number_debug_checkbox').style.display = '';
    } else {
      e.currentTarget.closest('form').querySelector('#tax_number_selected_checkboxes,#tax_number_debug_checkbox').style.display = 'none';
    }
  });
});