document.addEventListener('DOMContentLoaded', function() {

  let currency_element = document.getElementById("currency_select")
  if(currency_element) {
    currency_element.addEventListener('change', function() {
      var refresh_path = window.location.href.split('?')[0]
      var selected_currency = $('#currency_select')[0].value
      document.location = refresh_path+"?currency="+selected_currency 
    });
  }
});