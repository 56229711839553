// import M from "../shared/materialize-global";
import { FormSelect, Modal } from "@materializecss/materialize";

import { documentOn, toggle, multiSelectValues, hideElements } from "../shared/jquery-alternative";
import { loadSelect2, loadTagIt } from '../shared/load-dynamic';
import { applySlimSelect, loadSlimSelect, loadSlimSelectAjax } from "../shared/slim-select-wrapper";
import { initScheduleView } from '../manager/schedules';

documentOn('submit', 'form.remote-ajax', function(e) {
  e.preventDefault();
  const me = this;
  const formData = new FormData(this);
  fetch(this.action, {
    method: this.method,
    headers: {
      "Accept": "application/json" 
    },
    body: formData
  }).then(response => response.json()).then(response => {
    if (!!response && !!response.errors && (response.errors.length > 0)) {
      me.querySelector('input[type=submit]').disabled = false;
      alert(response.errors.join("\n"));
    } else {
      const elem = me.closest('.modal');
      // const instance = Modal.getInstance(elem);
      // instance.close();
      elem.hidePopover();        
      window.location.reload();
    }
  }).catch(error => {
    console.error("Error: ", error)
  })
});

window.ingredient_and_menu_item_update_modals = function() {

  document.getElementById('location_email_edit_button')?.addEventListener('click', function() {
    toggle(document.querySelector('.location_email_edit'));
    toggle(document.querySelector('.location_email_value'));
  });

  document.getElementById('location_phone_edit_button')?.addEventListener('click', function() {
    toggle(document.querySelector('.location_phone_edit'));
    toggle(document.querySelector('.location_phone_value'));
  });

  document.querySelector("a[id^='il_title_']")?.addEventListener('click', function(event) {
    event.preventDefault();
    const il_id = event.target.getAttribute("id").split('_')[2];
    const loc_id = event.target.getAttribute("data-location");
    const menu_id = event.target.getAttribute("data-menu");
    const menu_item_id = event.target.getAttribute("data-menu-item");
    const auth_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    return fetch(
      "/manager/locations/" + loc_id + "/menus/" + menu_id + "/menu_items/" + menu_item_id + "/ingredient_lists/" + il_id + "/edit"
    ).then(response => response.json().then(data => {
      document.querySelector("#il_modal").querySelector('.modal-content').innerHTML = '';
      if (data.description === null) {
        data.description = '';
      }
      document.querySelector("#il_modal").querySelector('.modal-content').append(
        `\
\
`
      );

      Modal.init(document.getElementById('il_modal'));
    }));
  });

  document.querySelector("a[id^='mii_ingredient_']")?.addEventListener('click', function(event) {
    event.preventDefault;
    const mii_id = event.target.getAttribute("id").split('_')[2];
    const loc_id = event.target.getAttribute("data-location");
    const menu_id = event.target.getAttribute("data-menu");
    const menu_item_id = event.target.getAttribute("data-menu-item");
    const il_id = event.target.getAttribute("data-ingredient-list");
    const auth_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    fetch(
      "/manager/locations/" + loc_id + "/menus/" + menu_id + "/menu_items/" + menu_item_id + "/ingredient_lists/" + il_id + "/menu_item_ingredients/" + mii_id + "/edit.json"
    ).then(response => response.json().then(data => {
      document.querySelector("#mii_modal").querySelector('.modal-content').innerHTML = '';
      document.querySelector("#mii_modal").querySelector('.modal-content').innerHTML =
        `\
<div id='new-forms'>\
<div class='row'>\
<br/> \
<div class='col l3 m3 hide-on-med-and-down'>&nbsp;</div> \
<div class='col l6 m12'>\
<h4 class='man-head-small center-align'>Edit '`+ data.name + `'</h4>\
<br/>\
\
<form class='edit_menu_item_ingredient' id=edit_menu_item_ingredient_`+ data.id + " action='/manager/locations/" + loc_id + "/menus/" + menu_id + "/menu_items/" + menu_item_id + "/ingredient_lists/" + il_id + "/menu_item_ingredients/" + data.id + "/update_menu_item_ingredient?ingredient_list_id=" + il_id + "&amp;menu_item_ingredient_id=" + data.id + `' accept-charset='UTF-8' method='post'>\
<input type='hidden' name='authenticity_token' value=`+ auth_token + `>\
<div class='input first'>\
<label for='menu_item_ingredient_ingredient_attributes_name' class='active'>Name</label>\
<input type='text' value='`+ data.ingredient.name + `' name='menu_item_ingredient[ingredient_attributes][name]' id='menu_item_ingredient_ingredient_attributes_name'>\
</div>\
<div class='input'>\
<label for='menu_item_ingredient_ingredient_attributes_description'>Description</label>\
<input type='text' value='`+ data.ingredient.description + `' name='menu_item_ingredient[ingredient_attributes][description]' id='menu_item_ingredient_ingredient_attributes_description'>\
</div>\
<input type='hidden' value=`+ data.ingredient.id + ` name='menu_item_ingredient[ingredient_attributes][id]' id='menu_item_ingredient_ingredient_attributes_id'>\
<div class='input'>\
<label for='menu_item_ingredient_delta_price'>Delta Price</label>\
<input type='number' value='`+ data.delta_price + `' name='menu_item_ingredient[delta_price]' id='menu_item_ingredient_delta_price'>\
</div>\
<div class='input col s6 no-pad-left'>\
<label for='menu_item_ingredient_minimum'>Minimum</label>\
<input type='number' value=`+ data.minimum + ` name='menu_item_ingredient[minimum]' id='menu_item_ingredient_minimum'>\
</div>\
<div class='input col s6 no-pad-right'>\
<label for='menu_item_ingredient_maximum'>Maximum</label>\
<input type='number' value=`+ data.maximum + ` name='menu_item_ingredient[maximum]' id='menu_item_ingredient_maximum'>\
</div>\
<div class='clearfix'></div>\
\
<div class='manager-btn left'>\
<i class='waves-effect waves-light btn waves-input-wrapper' style=''><input type='submit' name='commit' value='Submit' class='waves-button-input'></i>\
</div>\
<div class='manager-btn right'>\
<a data-confirm='Are you sure?' class='waves-effect waves-light btn del-btn' rel='nofollow' data-method='delete' href='/manager/locations/`+ loc_id + "/menus/" + menu_id + "/menu_items/" + menu_item_id + "/ingredient_lists/" + il_id + "/menu_item_ingredients/" + mii_id + `'>Delete</a>\
</div>\
</form>\
</div>\
<div class='col l3 m3 hide-on-med-and-down'>&nbsp;</div> \
</div>\
</div>\
`;
      Modal.init(document.getElementById('mii_modal'));
    }));
  });
};

function handleDeliveryCriteriaModal() {
  documentOn('change', '.delivery-criteria', function(e) {
    const values = multiSelectValues(e.target);

    hideElements(e.target.closest('.nested-fields').querySelectorAll('.location_delivery'));
    let criteriaFieldSet;
    values.forEach(value => {
      criteriaFieldSet = e.target.closest('.nested-fields').querySelector(`.${ value }`);
      criteriaFieldSet.style.display = '';
      criteriaFieldSet.querySelector('[id^=location_deliveries_attributes]').value = '';
      if (criteriaFieldSet.querySelector('.slim-select:not(.initialized')) {
        applySlimSelect(criteriaFieldSet.querySelector('.slim-select:not(.initialized'))
        criteriaFieldSet.querySelector('.slim-select:not(.initialized').classList.add('initialized');
      }
    });

  });
}

document.addEventListener('DOMContentLoaded', () => {
  let elems = document.querySelectorAll('.modal');
  const options = {
    onOpenEnd: function(modal, trigger) {
      elems = modal.querySelectorAll('select:not(.select2)');
      if (elems.length > 0) FormSelect.init(elems);

      loadSlimSelect("select.slim-select", modal)
      loadSlimSelectAjax("select.slim-select-ajax", modal);    
    }
  };
  if (elems.length > 0) Modal.init(elems, options);

  if (document.getElementById('location_email_edit_button')) {
    ingredient_and_menu_item_update_modals();

  }
  handleDeliveryCriteriaModal();

  documentOn('click', '.modal-close', function(e) {
    e.target.closest('.modal').hidePopover();
  })
  documentOn('click', '.modal-trigger-ajax', function(e) {
    e.preventDefault();
    const modalSelector = this.getAttribute('href');
    const modals = document.querySelectorAll(modalSelector);
    let url = this.dataset.url;
    if (!url) { url = modals[0].data('url'); } //adding for backward compatibility. Ideally triggers should now have url instead of modal which are reused.
    const options = {
      onOpenEnd: function(modal, trigger) {
        loadSlimSelect("select.slim-select", modal)
        loadSlimSelectAjax("select.slim-select-ajax", modal);    
      }
    };
    fetch(url, {
      headers: {
        "Accept": "text/html",
        "Turbo-Frame": modalSelector
      }
    }).then(response => response.text()).then(body => {
      modals[0].querySelector('.modal-content').innerHTML = body;
      let mmodals = Modal.init(modals, options);
      // M.updateTextFields();
      initScheduleView();

      loadSlimSelectAjax("select.slim-select-ajax", modals[0])
      mmodals[0].el.showPopover();
    });
  });

  document.addEventListener('cocoon:after-insert', (e) => {
    const parentNode = e.target.parentNode;
    const wrapperClass = parentNode.querySelectorAll('[data-wrapper-class]')[0]?.getAttribute('data-wrapper-class') || 'nested-fields';
    //const wrapperClass = btn.getAttribute('data-wrapper-class') || 'nested-fields';
    const nodes = parentNode.querySelectorAll(`.${ wrapperClass }`);
    const added_fields = nodes[nodes.length - 1];
    loadSlimSelect("select.slim-select", added_fields)
    loadSlimSelectAjax("select.slim-select-ajax", added_fields);    
  });

  documentOn('cocoon:after-insert', '.edit_menu_item', function(e) {
    // M.updateTextFields();
    // console.log(e.target);
    const parentNode = e.target.parentNode;
    const wrapperClass = parentNode.querySelectorAll('[data-wrapper-class]')[0]?.getAttribute('data-wrapper-class') || 'nested-fields';
    //const wrapperClass = btn.getAttribute('data-wrapper-class') || 'nested-fields';
    const nodes = parentNode.querySelectorAll(`.${ wrapperClass }`);
    const added_fields = nodes[nodes.length - 1];
    if (added_fields.querySelector('.option-price')) {
      added_fields.querySelectorAll(".option-price").forEach(optionPriceEl => {
        optionPriceEl.addEventListener('change', function() {
          let menu_item_el = document.getElementById("menu_item_price");
          menu_item_el.disabled = true;
          // menu_item_el.value = 0
        });
      });
    }
  });

  documentOn('cocoon:after-remove', '.edit_menu_item', function(e) {
    let removed_fields = e.detail;
    if (removed_fields.querySelector('.option-price')) {
      // check if this was the last option-price, and if so, then enable menu_item
      let cov_els = document.querySelectorAll('.nested-fields.component-option-value');
      let allGone = true;
      for (var i = 0, max = cov_els.length; i < max; i++) {

        var style = window.getComputedStyle(cov_els[i]);
        if (!((style.display === 'none') || (style.visibility === 'hidden'))) {
          allGone = false;
        }
      }

      if (allGone) {
        let menu_item_el = document.getElementById("menu_item_price");
        menu_item_el.disabled = false;
        menu_item_el.value = 0;
        // M.updateTextFields();

        // menu_item_el.classList.add('active')
      }
    }
  });

  documentOn('click', '#add_menu_item_option > a', e => setTimeout(function() {

    if (document.querySelector('.dropdown-content').closest('.menu-option-dropdown')) {
      document.querySelector('.menu-option-dropdown').querySelector('.dropdown-content').append("<div class='sep-line'><li><a class='option-modal' id='option-modal' href='#modal1'>Create New</a></li></div>");
      document.querySelectorAll('.option-modal').forEach(optionModalEl => {
        optionModalEl.addEventListener('click', function(e) {
          let elem = document.querySelector('#new-option-value-modal');
          elem.showPopover();
          setTimeout(function() {
            if (document.querySelector('.dropdown-content').closest('.option-dropdown').length > 0) {
              document.querySelector('.option-dropdown').querySelector('.dropdown-content').append("<div class='sep-line'><li><a class='option-value-modal' href='#modal2'>Create New Option</a></li></div>");
            }
            document.querySelectorAll('.option-value-modal').forEach(optionValueModalEl => {
              optionValueModalEl.addEventListener('click', function() {
                elem = document.querySelector('#new-option-modal');
                // instance = Modal.getInstance(elem);
                // instance.open();
                elem.showPopover();
              });
            });
          }
            , 1000);
        });
      }).click();
    }
  }, 1000));

});
