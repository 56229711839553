import $ from "../shared/jquery-global";
import 'jquery-parallax.js/parallax';

import "@oddcamp/cocoon-vanilla-js"; //used in manager-modals and inventory

import '../manager/manager-modals';
import '../manager/managers';
import '../manager/inventory';
import '../manager/social_sharing';
import '../manager/sms_gateways';
import '../shared/foodnerd-print';

import { I18nRails, initLocale } from "../shared/rails-i18n-js";

import { loadTagIt } from '../shared/load-dynamic';

import { loadSlimSelect, loadSlimSelectAjax } from "../shared/slim-select-wrapper";

import { offset } from '../shared/jquery-alternative';

initLocale();
function setupLocale() {
  var locale = I18nRails.locale;

  if (locale == 'ar') {
    document.querySelector('html').setAttribute('dir', 'rtl');
    document.querySelector('body').classList.add('ar');
    document.querySelectorAll('.brand-menu').forEach(function(el) {
      el.classList.add('left');
      el.addEventListener('click', function() {
        document.querySelector('#slide-out')?.classList.remove('hidesidebar');
        document.querySelector('#hubspot-messages-iframe-container').style.zIndex = '0';
      });
    });
    document.querySelector('#slide-out').style.transform = 'translateX(105 %)';
    document.querySelectorAll('table').forEach(function(el) {
      el.setAttribute('dir', 'ltr');
    });
    document.querySelectorAll('.sidenav-overlay').forEach(function(el) {
      el.addEventListener('click', function() {
        document.querySelector('#slide-out')?.classList.add('hidesidebar');
        document.querySelector('#hubspot-messages-iframe-container').style.zIndex = '';
      });
    });
  }
  else if (locale == 'ur') {
    document.querySelector('html').setAttribute('dir', 'rtl');
    document.querySelector('body').classList.add('ar', 'ur');
    document.querySelectorAll('.brand-menu').forEach(function(el) {
      el.classList.add('left');
      el.addEventListener('click', function() {
        document.querySelector('#slide-out')?.classList.remove('hidesidebar');
        document.querySelector('#hubspot-messages-iframe-container').style.zIndex = '0';
      });
    });
    document.querySelector('#slide-out').style.transform = 'translateX(105 %)';
    document.querySelectorAll('table').forEach(function(el) {
      el.setAttribute('dir', 'ltr');
    });
    document.querySelectorAll('.sidenav-overlay').forEach(function(el) {
      el.addEventListener('click', function() {
        document.querySelector('#slide-out')?.classList.add('hidesidebar');
        document.querySelector('#hubspot-messages-iframe-container').style.zIndex = '';
      });
    });
  }
  else if (locale == 'en') {
    document.querySelector('html').setAttribute('dir', 'ltr');
    document.querySelector('body').classList.remove('ar', 'ur');
    document.querySelectorAll('.brand-menu').forEach(function(el) {
      el.classList.remove('left');
    });
    let slideOut = document.querySelector('#slide-out');
    if (slideOut) slideOut.style.transform = 'translateX(-105%)';
  }
}

document.addEventListener('DOMContentLoaded', function() {
  setupLocale();

  loadSlimSelect("select.slim-select")
  loadSlimSelectAjax("select.slim-select-ajax");
  window.loadSlimSelect = loadSlimSelect
  const sidebar = document.querySelector('.sidebar');
  if (sidebar) {
    let offsetSidebar = offset(sidebar);
    const topPadding = 290;
    window.addEventListener('scroll', function() {
      const $sidebar = $(sidebar);
      if ((window.scrollTop + 220) > offsetSidebar.top) {
        $sidebar.stop().animate({ marginTop: (window.scrollTop - (offsetSidebar.top)) + topPadding });
      } else {
        $sidebar.stop().animate({ marginTop: 40 });
      }
    });
  }

  document.querySelectorAll('.overview').forEach(el => {
    el.addEventListener('click', e => $('html,body').animate({ scrollTop: offset(document.querySelector('.scroll1')).top }, 'slow'));
  });
  document.querySelectorAll('.services').forEach(el => {
    el.addEventListener('click', e => $('html,body').animate({ scrollTop: offset(document.querySelector('.why-choose-bg')).top }, 'slow'));
  });
  document.querySelectorAll('.marketing').forEach(el => {
    el.addEventListener('click', e => $('html,body').animate({ scrollTop: offset(document.querySelector('.scroll2')).top }, 'slow'));
  });
  document.querySelectorAll('.howitworks').forEach(el => {
    el.addEventListener('click', e => $('html,body').animate({ scrollTop: offset(document.querySelector('.howitworks-bg')).top }, 'slow'));
  });

  if (document.querySelector('#social_campaign_item_list')) {
    loadTagIt().then(() => {
      const me = $('#social_campaign_item_list');
      me.tagit({
        availableTags: ["Location", "Menu", "Menu Items", "Offers"],
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: me.data('source')
        },
        placeholderText: 'Type in items'
      });
    });
  }
  if (document.querySelector('#social_twitter_campaign_item_list')) {
    loadTagIt().then(() => {
      const me = $('#social_twitter_campaign_item_list');
      console.log("Inside");
      me.tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('social_twitter_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  let multiChecboxEls = document.querySelectorAll('input.multi-checkbox')
  multiChecboxEls.forEach((el) => {
    el.addEventListener('click', (e) => {
      document.querySelectorAll(e.target.dataset.childSelector).forEach((childEl) => {
        childEl.checked = e.target.checked
      })
    })    
  });


  document.getElementById('social_campaign_place_list')?.addEventListener('keyup', function(e) {
    if (document.querySelector('.place-tags')) {
      loadTagIt().then(() => {
        const me = $('.place-tags');
        me.tagit({
          allowSpaces: true,
          autocomplete: {
            minLength: 2,
            source: me.data('autocomplete-source')
          },
          placeholderText: 'Type in items'
        });
      });
    }
  });
});

//  elems = document.querySelectorAll('.materialboxed')
//  instances = M.Materialbox.init(elems, null)

