import { isElectron } from "react-device-detect";
import isMobile from 'ismobilejs';

let ipcRenderer = null;
if (isElectron) {
  const electron = window.require("electron");
  ipcRenderer = electron.ipcRenderer;
}

export var notifyJsMessages = function() {
  if (!window) { return; }
  if (isElectron || !!window.foodnerdApi || (!!window.webkit && !!window.webkit.messageHandlers.foodnerdApi)) {
    document.querySelectorAll('.js-message').forEach(function(msgElement) {
      if (!!window.foodnerdApi) {
        var event = msgElement.getAttribute('data-event');
        if (!!msgElement.getAttribute('data-location')) {
          event = `${ event }|${ msgElement.getAttribute('data-location') }`;
        }

        if (!!msgElement.getAttribute('data-message')) {
          foodnerdApi.postMessage(event, msgElement.getAttribute('data-message'));
        } else {
          foodnerdApi.postMessage(event, `${ msgElement.getAttribute('data-error') }${ msgElement.getAttribute('data-warn') }${ msgElement.getAttribute('data-notice') }`);
        }
      }
      else if (!!window.webkit && !!window.webkit.messageHandlers.foodnerdApi) {
        var event = msgElement.getAttribute('data-event');
        if (!!msgElement.getAttribute('data-location')) {
          event = `${ event }|${ msgElement.getAttribute('data-location') }`;
        }
        window.webkit.messageHandlers.foodnerdApi.postMessage({
          'event': event,
          'error': msgElement.getAttribute('data-error'),
          'warn': msgElement.getAttribute('data-warn'),
          'notice': msgElement.getAttribute('data-notice'),
          'message': msgElement.getAttribute('data-message'),
          'location': msgElement.getAttribute('data-location')

        });
      } else {
        //electron
        // import('electron').then(electron => {
        //   const ipcRenderer = electron.ipcRenderer
        //const { ipcRenderer } = window.require('electron')
        const event = msgElement.getAttribute('data-event');
        if (!!msgElement.getAttribute('data-message')) {
          ipcRenderer.send(event, msgElement.getAttribute('data-message'));
        } else {
          const message = `${ msgElement.getAttribute('data-location') }${ msgElement.getAttribute('data-error') }${ msgElement.getAttribute('data-warn') }${ msgElement.getAttribute('data-notice') }`;
          ipcRenderer.send(event, message);
        }
        // })
      }
    });
  }
};


export var notifyNative = function(event, params) {
  if (isMobile(window.navigator.userAgent).any) {
    if (!!window.foodnerdApi) {
      if (!!params && !!params.message) {
        window.foodnerdApi.postMessage(event, params.message);
      } else {
        window.foodnerdApi.postMessage(event, 'JSON');
      }
    } else if (!!window.webkit && !!window.webkit.messageHandlers.foodnerdApi) {
      if (!params) {
        window.webkit.messageHandlers.foodnerdApi.postMessage({
          'event': event,
          'message': 'JSON'
        });
      } else {
        window.webkit.messageHandlers.foodnerdApi.postMessage({
          'event': event,
          'error': params['data-error'],
          'warn': params['data-warn'],
          'notice': params['data-notice'],
          'message': params['data-message'],
          'location': params['data-location']
        });
      }
    } else if (!!window.originalPostMessage) {
      window.postMessage(event);
    }
  }
};
