import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, onMessage, getToken } from 'firebase/messaging';
import firebaseConfig from './firebase-config';
import localStorageMemory from 'localstorage-memory';

export function enableFirebaseNotifications(onMessageListener) {
  if (!isSupported()) { return; }
  if (typeof Notification == "undefined") { return; }
  Notification.requestPermission().then(function(permission) {
    if (permission !== 'granted') { return; }
    console.log('Notification permission granted.');
    if (!document.body.querySelector('react-view')) {
      const firebaseApp = initializeApp(firebaseConfig);
    }
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.messageType && event.data.messageType === 'navigate') {
        window.location.href = event.data.url;
      } else if (event.data.data && event.data.data.link && event.data.messageType === 'notification-clicked') {
        window.location.href = event.data.data.link;
      }
    });
    // navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
    // const messaging = getMessaging(firebaseApp);
    const messaging = getMessaging();
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    // getToken(messaging, { serviceWorkerRegistration: registration }).then(function(currentToken) {
    getToken(messaging).then(function(currentToken) {
      if (!!currentToken) {
        console.log('Got Token:', currentToken);
        saveFirebaseToken(currentToken);
      } else {
        console.log('No Instance ID token available. Request permission to generate one.');
      }
    }).catch(function(err) {
      console.error('An error occurred while retrieving token. ', err);
    });

    try {
      // const messaging = getMessaging(firebaseApp);
      // const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        if (onMessageListener) onMessageListener(payload);
      });

      //Callback fired if Instance ID token is updated.
      //   messaging.onTokenRefresh(() => messaging.getToken().then(function(refreshedToken) {
      //     console.log('Token refreshed.');
      //     //Indicate that the new Instance ID token has not yet been sent to the
      //     //app server.
      //     saveFirebaseToken(currentToken);
      //   }).catch(function(err) {
      //     console.log('Unable to retrieve refreshed token ', err);
      //     showToken('Unable to retrieve refreshed token ', err);
      //   }));
    } catch (e) {
      console.log('Unable to setup messaging', e);
    }
    // }).catch(function(err) {
    //   console.error('Unable to get permission to notify.', err);
    // });

  }).catch(function(err) {
    console.error('Unable to get permission to notify.', err);
  });

  // }).catch((notificationPermissionError) => {
  //   console.log('SW registration failed: ', notificationPermissionError);
  // });

  function saveFirebaseToken(currentToken) {
    if (!!currentToken) {
      const storage = ((typeof window !== "undefined") && ("localStorage" in window)) ? window.localStorage : localStorageMemory;

      //key = "fb-token-#{Rails.user_id}-#{window.location.hostname}"
      let user_id = document.getElementsByTagName('body')[0].dataset.userId;
      const key = `device_token-${ user_id }-${ window.location.hostname }`;
      const oldToken = storage.getItem(key);
      if (oldToken !== currentToken) {
        fetch(`/users/${ user_id }/devices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            device_token: currentToken,
            old_token: oldToken,
            kind: 'web',
            app_name: 'venue',
            app_version: '2.0'
          })
        })
        .then(response => response.json().then((data) => {
          // Pass a key name to get its value.
          if(response.ok) {
            storage.setItem(key, currentToken);
          } else {
            console.error("Error response", data)
          }
        }))
        .catch(error => {
          console.error('error', error);
        })
      }
    }
  };
};
