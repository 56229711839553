
import $ from "../shared/jquery-global";
import { elementOn } from "../shared/jquery-alternative";

document.addEventListener('DOMContentLoaded', () => {
  elementOn(document.body, 'change', '.check-email-exist', function(event) {
    event.preventDefault();

    var url_path = document.getElementById('new_owner_association').action;
    var email_add = document.getElementById('owner_association_user_attributes_email').value;

    fetch(`${url_path}/check_user_exists?${new URLSearchParams({
      email_add: email_add
    })}`).then(function(response) {
      response.json().then(data => {
        if (data) {
          document.querySelectorAll('input.user-not-exist').forEach(el => {
            el.value = "";
            el.disabled = true;
            el.required = false;
          });
          document.querySelectorAll('label.user-not-exist').forEach(el => {
            el.classList.remove('required');
          });
        }
        else {
          document.querySelectorAll('input.user-not-exist').forEach(el => {
            el.disabled = false;
            el.required = true;
          });
          document.querySelector('#verified').value = true;
          document.querySelectorAll('label.user-not-exist').forEach(el => {
            el.classList.add('required');
          });
        } 
      })
    });//.done(

  });


  elementOn(document.body, 'click', '.form-btn-clicked', function(event) {
    if (!document.querySelector('.user-not-exist').disabled) {
      var matched,
        password = document.getElementById('owner_association_user_attributes_password').value,
        confirm = document.getElementById('owner_association_user_attributes_password_confirmation').value;

      matched = (password == confirm && password != "") ? true : false;
      if (matched) {
        return true;
      }
      else {
        document.querySelector('.cpass').innerHTML = "Confirmation password did not matched";
        return false;
      }
    }

  });
});