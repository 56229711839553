import { documentOn } from "../shared/jquery-alternative";
import ThermalPrinter from './thermal-printer';
import { notifyNative } from './mobile-communication';
import isMobile from 'ismobilejs';
import localStorageMemory from 'localstorage-memory';
import sweetalert2 from 'sweetalert2';
import { I18nRails } from '../shared/rails-i18n-js';
const storage = ((typeof window !== "undefined") && ("localStorage" in window)) ? window.localStorage : localStorageMemory;


let foodnerdPrint = {
  getPrinter(config) {
    return new ThermalPrinter(config);
  },

  getPrinterConfig(success, error) {
    foodnerdPrint.getPrinterModeConfig(config => foodnerdPrint.getPrinterRollConfig(function(print_roll_config) {
      if (!!success) { success($.extend(config, print_roll_config)); }
    })
      , function() {
        if (!!error) { error(); }
      });
  },

  promptPrinterConfig(success, error) {
    const me = this;
    foodnerdPrint.promptPrinterModeConfig(config => me.promptPrinterRollConfig(function(print_roll_config) {
      if (!!success) { success($.extend(config, print_roll_config)); }
    })
      , function() {
        if (!!error) { error(); }
      });
  },

  getPrinterModeConfig(success, error) {
    const selection = storage.getItem('thermal-printer');
    if (!selection) {
      foodnerdPrint.promptPrinterModeConfig(success, error);
      return;
    }
    if (selection === 'wifi') {
      let wifi_ip = storage.getItem('ip-wifi');
      let wifi_port = storage.getItem('port-wifi');
      if (!wifi_ip || !wifi_port) {
        foodnerdPrint.promptPrinterModeConfig(function() {
          wifi_ip = storage.getItem('ip-wifi');
          wifi_port = storage.getItem('port-wifi');
          success({ host: wifi_ip, port: wifi_port });
        }
          ,
          function() {
            if (!!error) { error(); }
          });
      } else {
        if (!!success) { success({ host: wifi_ip, port: wifi_port }); }
      }
    } else if (selection === 'bt') {
      const bt_name = storage.getItem('name-bt');
      if (!bt_name) {
        foodnerdPrint.promptBluetoothName(function() {
          if (!!success) { success({ name: bt_name }); }
        }
          ,
          function() {
            if (!!error) { error(); }
          });
      } else {
        if (!!success) { success({ name: bt_name }); }
      }
    } else if (selection === 'usb') {
      const usb_name = storage.getItem('name-usb');
      if (!usb_name) {
        foodnerdPrint.promptUSBName(function() {
          if (!!success) { success({ name: usb_name }); }
        }
          ,
          function() {
            if (!!error) { error(); }
          });
      } else {
        if (!!success) { success({ name: usb_name }); }
      }
    }
  },

  getPrinterRollConfig(success, error) {
    const val = storage.getItem('paper_roll');
    if (!val) {
      foodnerdPrint.promptPrinterRollConfig(success, error);
    } else {
      success({ paper_roll: val });
    }
  },

  promptPrinterRollConfig(success, error) {

    sweetalert2.fire({
      title: I18nRails.t('side_nav.print_size'),
      input: "text"
    })
      .then((selection) => {
        foodnerdPrint.extensionPromptPrinterRollConfig(success, selection.value, error);
      });
  },

  extensionPromptPrinterRollConfig(success, selection, error) {
    if (!!selection) {
      const index = parseInt(selection);
      if (index === 1) {
        storage.setItem('paper_roll', '80mm');
        if (!!success) { success(); }
      } else if (index === 2) {
        storage.setItem('paper_roll', '58mm');
        if (!!success) { success(); }
      } else {
        alert('Invalid selection');
        foodnerdPrint.promptPrinterRollConfig(success, error);
      }
    } else {
      if (!!error) { error(); }
    }
  },

  promptListSelection(list, message, success, error) {
    const selection = prompt(message);
    if (!!selection) {
      const index = parseInt(selection) - 1;
      if ((index >= 0) && (index < list.length)) {
        if (!!success) { success(list[index]); }
      } else {
        alert('Invalid selection');
        foodnerdPrint.promptListSelection(list, message, success, error);
      }
    } else {
      if (!!error) { error(); }
    }
  },

  promptBluetoothSelection(list, success, error) {
    const messages = ["Please choose bluetooth printer"];
    let idx = 1;
    list.forEach(function(option) {
      messages.push(`${ idx }. ${ option.name }`);
      idx += 1;
    });
    const message = messages.join(" ");

    foodnerdPrint.promptListSelection(list, message, function(option) {
      storage.setItem('name-bt', option.name);
      if (!!success) { success({ name: option.name }); }
    }
      , function() {
        if (!!error) { error(); }
      });
  },


  promptBluetoothName(success, error) {
    ThermalPrinter.discoverBtPrinters(function(list) {
      if (list.length < 1) {
        if (confirm("No bluetooth printer found. Retry?")) {
          foodnerdPrint.promptBluetoothName(success, error);
        }
      } else {
        if (typeof (list) === 'string') { list = [list]; }
        foodnerdPrint.promptBluetoothSelection(list, success, error);
      }
    });
  },


  promptUSBSelection(list, success, error) {
    const messages = ["Please choose USB printer"];
    let idx = 1;
    list.forEach(function(option) {
      messages.push(`${ idx }. ${ option }`);
      idx += 1;
    });
    const message = messages.join(" ");

    foodnerdPrint.promptListSelection(list, message, function(option) {
      storage.setItem('name-usb', option);
      if (!!success) { success({ name: option }); }
    }
      , function() {
        if (!!error) { error(); }
      });
  },

  promptUSBName(success, error) {
    ThermalPrinter.discoverUSBPrinters('', function(list) {

      if (list.length < 1) {
        if (confirm("No USB printer found. Retry?")) {
          foodnerdPrint.promptUSBName(success, error);
        }
      } else {
        if (typeof (list) === 'string') { list = [list]; }
        foodnerdPrint.promptUSBSelection(list, success, error);
      }
    });
  },

  promptPrinterPort(success, error) {
    sweetalert2.fire({
      title: I18nRails.t('side_nav.print_port'),
      input: "text"
    })
      .then((selection) => {
        foodnerdPrint.extensionPromptPrinterPort(success, selection.value, error);
      });
  },

  extensionPromptPrinterPort(success, selection, error) {

    if (!!selection) {
      storage.setItem('port-wifi', selection);
      if (!!success) { success(); }
    } else {
      if (!!error) { error(); }
    }
  },

  promptPrinterIp(success, error) {

    sweetalert2.fire({
      title: I18nRails.t('side_nav.print_ip'),
      input: "text"
    })
      .then((selection) => {
        foodnerdPrint.extensionPromptPrinterIp(success, selection.value, error);
      });
  },

  extensionPromptPrinterIp(success, selection, error) {
    if (!!selection) {
      storage.setItem('ip-wifi', selection);
      foodnerdPrint.promptPrinterPort(success, error);
    } else {
      if (!!error) { error(); }
    }
  },

  promptPrinterName(success, error) {
    // const selection = prompt('Please provide BT name for thermal printer', 'BT Printer');
    sweetalert2.fire({
      title: "Please provide BT name for thermal printer",
      input: "text"
    })
      .then((selection) => {
        foodnerdPrint.extensionPromptPrinterName(success, selection.value, error);
      });
  },

  extensionPromptPrinterName(success, selection, error) {

    if (!!selection) {
      storage.setItem('name-bt', selection);
      if (success) { success(); }
    } else {
      if (!!error) { error(); }
    }
  },

  promptPrinterModeConfig(success, error) {
    if (!window.cordova) {
      sweetalert2.fire({
        title: I18nRails.t('side_nav.print_setting'),
        input: "text"
      })
        .then((selection) => {
          foodnerdPrint.extensionPromptPrinterModeConfig(success, selection.value, error);
        });
    } else {
      sweetalert2.fire({
        title: "Select 1 for Wifi and 2 for Bluetooth",
        input: "text"
      })
        .then((selection) => {
          foodnerdPrint.extensionPromptPrinterModeConfig(success, selection.value, error);
        });
    }
  },

  extensionPromptPrinterModeConfig(success, selection, error) {

    if (!!selection) {
      const index = parseInt(selection);
      if (index === 1) { //wifi
        storage.setItem('thermal-printer', 'wifi');
        foodnerdPrint.promptPrinterIp(success, error);
      } else if (index === 2) { //bluetooth or usb
        if (!window.cordova) {
          storage.setItem('thermal-printer', 'usb');
          foodnerdPrint.promptUSBName(success, error);
        } else {
          storage.setItem('thermal-printer', 'bt');
          foodnerdPrint.promptBluetoothName(success, error);
        }
      } else {
        alert('Invalid selection');
        foodnerdPrint.promptPrinterModeConfig(success, error);
      }
    } else {
      if (!!error) { error(); }
    }
  },

  activateItemSalesReport(print_btn_element, remote_url){
    if (print_btn_element == null) { print_btn_element = '.item-sales-print-report'; }
    if (remote_url == null) { remote_url = ''; }
    documentOn('click', print_btn_element, function(e) {

      e.preventDefault();
      if (this.classList.contains('disabled')) {
        alert('Print in progress');
        return;
      }
      this.classList.add('disabled');
      const me = this;
      const locationSlug = this.dataset.locationslug;
      fetch(`/manager/locations/${locationSlug}/order_items/sales${window.location.search}`, 
        {headers:{
          ACCEPT: "application/json"
        }}).then(response => response.json().then(itemSalesData => {
        try {
          foodnerdPrint.getPrinterConfig(function(config) {
            foodnerdPrint.getPrinter(config).printItemSalesReport(itemSalesData);
            me.classList.remove('disabled');
          }
            , error => me.classList.remove('disabled'));
        } finally {
          me.classList.remove('disabled');
        }
      })).catch(() => me.classList.remove('disabled'));
    });
  },


  activatePrintSalesReport(print_btn_element, remote_url) {
    if (print_btn_element == null) { print_btn_element = '.print-report'; }
    if (remote_url == null) { remote_url = ''; }
    documentOn('click', print_btn_element, function(e) {

      e.preventDefault();
      if (this.classList.contains('disabled')) {
        alert('Print in progress');
        return;
      }

      this.classList.add('disabled');
      //      drawer_id = foodnerdPos.getDrawerTitle()
      const per = this.dataset.per || 1000;
      const from_time = this.dataset.from;//FoodnerdPos.get_location().id
      const to_time = this.dataset.to;//FoodnerdPos.get_location().id
      const url = this.dataset.url;
      const timezone = this.dataset.timezone;
      if (isMobile(window.navigator.userAgent).any) {
        notifyNative('onPrint', {
          message: JSON.stringify({
            url,
            data: {
              per,
              from: from_time,
              to: to_time,
              timezone
            }
          })
        }
        );
        return;
      }

      const me = this;
      fetch(`/manager/orders/sales${window.location.search}`, 
        {headers:{
          ACCEPT: "application/json"
        }}).then(response => response.json().then(salesData => {
        try {
          foodnerdPrint.getPrinterConfig(function(config) {
            foodnerdPrint.getPrinter(config).printSalesReport(salesData);
            me.classList.remove('disabled');
          }
            , error => me.classList.remove('disabled'));
        } finally {
          me.classList.remove('disabled');
        }
      })).catch(() => me.classList.remove('disabled'));
    });
  },

  activatePrintReceipt(print_btn_element, remote_url) {
    if (print_btn_element == null) { print_btn_element = '.print-order'; }
    if (remote_url == null) { remote_url = ''; }
    documentOn('click', print_btn_element, function(e) {
      e.preventDefault();
      if (this.classList.contains('disabled')) {
        alert('Print in progress');
        return;
      }

      this.classList.add('disabled');
      let order_id = this.dataset.orderId;
      if (!order_id) {
        order_id = this.closest('.order').dataset.orderId;
      }
      let duplicate_bill = this.closest('.order').dataset.orderDuplicate_bill;
      const me = this;
      if (duplicate_bill != 'true') {
        duplicate_bill = false;
      }
      fetch(`/manager/orders/${ order_id }.json`).then(response => response.json().then(order => {
        try {
          foodnerdPrint.getPrinterConfig(function(config) {
            foodnerdPrint.getPrinter(config).printOrderReceipt(order, duplicate_bill);
            me.classList.remove('disabled');
          }
            , error => me.classList.remove('disabled'));
        } finally {
          me.classList.remove('disabled');
        }
      })).catch(() => me.classList.remove('disabled'));
    });
  }
};

export default foodnerdPrint;
document.addEventListener('DOMContentLoaded', function() {
  foodnerdPrint.activatePrintReceipt();
  foodnerdPrint.activatePrintSalesReport();
  foodnerdPrint.activateItemSalesReport();
  document.querySelector('.reset-print-settings')?.addEventListener('click', () => foodnerdPrint.promptPrinterConfig());
});