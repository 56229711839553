import $ from "../shared/jquery-global";
import { documentOn, showElements, hideElements } from "../shared/jquery-alternative";
import { I18nRails } from '../shared/rails-i18n-js';
import { Modal } from '@materializecss/materialize';
import { applySlimSelectAutocompleteAndCreate, applySlimSelectWithData } from "../shared/slim-select-wrapper";

document.addEventListener('DOMContentLoaded', function () {

  const customizable_ingredient_css = '.switch .customizable';
  //set customizable state, and returns total checked count
  const set_customizable_state = function (me, checked_count) {
    const checked = me.checked;
    const mii_element = me.closest('.menu_item_ingredient');
    if (checked) {
      showElements(mii_element.querySelectorAll('.min'));
      showElements(mii_element.querySelectorAll('.max'));
      showElements(mii_element.querySelectorAll('.delta_price'));
      checked_count += 1;
    } else {
      hideElements(mii_element.querySelectorAll('.min'));
      hideElements(mii_element.querySelectorAll('.max'));
      hideElements(mii_element.querySelectorAll('.delta_price'));
      checked_count -= 1;
    }

    const ingredient_list_element = me.closest('.ingredient_list');

    checked_count = 0;
    ingredient_list_element.querySelectorAll(customizable_ingredient_css).forEach((el) => {
      if (el.checked) { return checked_count += 1; }
    });

    if (checked_count < 1) {
      hideElements(ingredient_list_element.querySelectorAll('.min_ingredients'));
      hideElements(ingredient_list_element.querySelectorAll('.max_ingredients'));
    } else {
      showElements(ingredient_list_element.querySelectorAll('.min_ingredients'));
      showElements(ingredient_list_element.querySelectorAll('.max_ingredients'));
    }
  };

  document.querySelectorAll(customizable_ingredient_css).forEach((element) => {
    set_customizable_state(element);
    element.addEventListener('change', (e) => set_customizable_state(element));
  });


  const setup_unit_autocomplete = function (element) {
    if (element) {
      const list = element.closest('form').dataset.units;
      if (list) {
        const unitOptions = list.split(',').map(value => ({text: value, value }));
        element.classList.add('slim-st')
        applySlimSelectWithData(element, unitOptions)
      }
    }
  };

  const setup_ingredient_autocomplete = (elements, location_id) => {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.classList.add('slim-st')
        const id = element.getAttribute('id');
        applySlimSelectAutocompleteAndCreate({
          element, 
          urlPath: `/manager/locations/${location_id}/stock_purchases/autocomplete`,
          createUrlPath: `/manager/locations/${location_id}/ingredients/new?select_id=${id}`,
          createUrlLabel: I18nRails.t('stock_item.create_ingredient'),
          formatRecord: (record) => ({value: record.id.toString(), text: record.text}),
          getInitialParams: null,
          getSearchParams: null,
          responseDataKey: null,
          minimumInputLength: 1,
        })        
      })
    }
  };

  const setup_ingredient_only_autocomplete = function (elements, location_id, url) {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.classList.add('slim-st')
        const id = element.getAttribute('id');
        applySlimSelectAutocompleteAndCreate({
          element, 
          urlPath: url || `/manager/locations/${location_id}/stock_purchases/autocomplete`,
          createUrlPath: `/manager/locations/${location_id}/ingredients/new?select_id=${id}`,
          createUrlLabel: I18nRails.t('stock_item.create_ingredient'),
          formatRecord: (record) => ({value: record.id.toString(), text: record.text, data: {...record}}),
          getInitialParams: null,
          getSearchParams: null,
          responseDataKey: null,
          minimumInputLength: 1,
          events: {
            afterChange: (newOptions) => {
              if(newOptions?.[0]?.data?.stock_item_quantity) {
                element.closest('.nested-fields').querySelector('#stock_item_quantity').value = newOptions[0].data.stock_item_quantity;
              }
            }
          }
        })        
      })
    }
  };

  const setup_default_stock_location_ingredient_only_autocomplete = function (elements, location_id, stock_location_id, url) {
    if (elements.length > 0) {
      elements.forEach(element => {
        element.classList.add('slim-st')
        const id = element.getAttribute('id');
        applySlimSelectAutocompleteAndCreate({
          element, 
          urlPath: url || `/manager/locations/${location_id}/stock_purchases/autocomplete`,
          createUrlPath: `/manager/locations/${location_id}/ingredients/new?select_id=${id}`,
          createUrlLabel: I18nRails.t('stock_item.create_ingredient'),
          formatRecord: (record) => ({value: record.id.toString(), text: record.text, data: {...record}}),
          getInitialParams: null,
          getSearchParams: null,
          responseDataKey: null,
          minimumInputLength: 1,
          events: {
            afterChange: (newOptions) => {
              if(newOptions?.[0]?.data?.default_stock_location_stock_item_quantity) {
                element.closest('.nested-fields').querySelector('#stock_item_quantity').value = newOptions[0].data.default_stock_location_stock_item_quantity;
              }
            }
          }
        })        
      })
    }
  };

  const enable_stock_item_autocomplete = function (element, locationId) {
    if (!locationId && element.dataset.locationId) locationId = element.dataset.locationId;
    const stockLocation = element.closest('form').querySelector("#stock_location_id")

    // setup_unit_autocomplete(element.querySelector(".unit select"), location_id);
    setup_ingredient_autocomplete(element.querySelectorAll(".stockable_key select"), locationId);
    setup_ingredient_only_autocomplete(element.querySelectorAll(".ingredient_id select"), locationId);
    // setup_ingredient_only_autocomplete(element.querySelector(".ingredient_id_only select"), location_id, `/manager/locations/${location_id}/ingredients/autocomplete`);
    if (stockLocation && locationId){
      const stockLocationId = stockLocation.value
      setup_default_stock_location_ingredient_only_autocomplete(
        element.querySelectorAll(".ingredient_id_only.default_stock_location_ingredients select"), 
        locationId, 
        stockLocationId, 
        `/manager/locations/${locationId}/ingredients/autocomplete?stock_location_id=${stockLocationId}`) 
    }     
  };

  const enable_stock_item_purchase_autocomplete = function (element) {
    const location_id = element.closest('form').dataset.locationId;
    enable_stock_item_autocomplete(element, location_id);
  };

  const enable_stock_item_waste_autocomplete = function (form_element) {
    const location_id = form_element.dataset.locationId;
    setup_unit_autocomplete(form_element.querySelector(".unit select"))
    setup_ingredient_autocomplete(form_element.querySelectorAll(".stockable_key select"), location_id);
  };

  if (document.querySelector('form.edit_stock_purchase, form.new_stock_purchase')) {
    document.querySelectorAll(".stock_item_purchase").forEach(el => {
      enable_stock_item_purchase_autocomplete(el);
    });
    documentOn('cocoon:after-insert', 'form.edit_stock_purchase , form.new_stock_purchase', (e) => {
      const parentNode = e.target.parentNode;
      const wrapperClass = parentNode.querySelectorAll('[data-wrapper-class]')[0]?.getAttribute('data-wrapper-class') || 'nested-fields';
      const nodes = parentNode.querySelectorAll(`.${wrapperClass}`);
      const added_fields = nodes[nodes.length - 1];
      enable_stock_item_purchase_autocomplete(added_fields);
    });
  }

  if (document.querySelector('form.edit_stock_item, form.new_stock_item')) {
    enable_stock_item_autocomplete(document.querySelector('form.edit_stock_item, form.new_stock_item'));
  }

  if (document.querySelectorAll('form.edit_menu_item, form.new_menu_item')) {
    document.querySelectorAll(".menu_item_ingredient").forEach(el => {
      enable_stock_item_purchase_autocomplete(el);
    });
    documentOn('cocoon:after-insert', 'form.edit_menu_item, form.new_menu_item', function (e) {
      const parentNode = e.target.parentNode;
      const wrapperClass = parentNode.querySelectorAll('[data-wrapper-class]')[0]?.getAttribute('data-wrapper-class') || 'nested-fields';
      //const wrapperClass = btn.getAttribute('data-wrapper-class') || 'nested-fields';
      const nodes = parentNode.querySelectorAll(`.${wrapperClass}`);
      const added_fields = nodes[nodes.length - 1];
      enable_stock_item_purchase_autocomplete(added_fields);
      let customizable_ingredient_el = added_fields.querySelector(customizable_ingredient_css);
      if (customizable_ingredient_el) {
        set_customizable_state(customizable_ingredient_el);
        customizable_ingredient_el.addEventListener('change', function (e) {
          set_customizable_state(e.target);
        });
      }
    });
  }

  if (document.querySelector('form.new_stock_item_waste, form.edit_stock_item_waste')) {
    enable_stock_item_waste_autocomplete(document.querySelector('form.new_stock_item_waste, form.edit_stock_item_waste'));
    document.querySelector('form.new_stock_item_waste, form.edit_stock_item_waste').addEventListener('cocoon:after-insert', (e) => {
      const parentNode = e.target.parentNode;
      const wrapperClass = parentNode.querySelectorAll('[data-wrapper-class]')[0]?.getAttribute('data-wrapper-class') || 'nested-fields';
      const nodes = parentNode.querySelectorAll(`.${wrapperClass}`);
      const added_fields = nodes[nodes.length - 1];
      enable_stock_item_waste_autocomplete(added_fields);
    });
  }

  documentOn('keyup', 'form.edit_stock_purchase .adjusted_price input, form.new_stock_purchase .adjusted_price input', function (e) {
    const tr = e.target.closest('.stock_item_purchase');
    const quantity = tr.querySelector(".quantity input").value;
    const unit_price = tr.querySelector(".unit_price input");
    const adjusted_price = e.target.value;
    if (!!quantity && !!adjusted_price) {
      unit_price.value = parseFloat(adjusted_price) / parseFloat(quantity);
      update_stock_purchase_totals(this);
    }
  });

  function update_stock_purchase_totals(cthis) {
    const main_container = document.querySelector('.stock_item_purchases_main_container');
    const total_fields = document.querySelector(".stock_purchases_total_amount_fields.stock_purchases_relate_supplier_property");
    var total_amount = 0;
    main_container.querySelectorAll('.stock_item_purchase').forEach(el => {
      var tax_value = parseFloat(el.querySelector(".item_tax input").value || 0);
      var discount_value = parseFloat(el.querySelector(".item_purchase_discount input").value || 0);
      total_amount += parseFloat(el.querySelector(".unit_price input").value) * parseFloat(el.querySelector(".quantity input").value) + tax_value - discount_value;
    });
    const charges = parseFloat(document.querySelector("#stock_purchase_fees_attributes_0_amount").value);
    if (!!charges) total_amount += charges;

    total_fields.querySelector('.total_amount input').value = total_amount;

    let paid_amount = total_fields.querySelector(".paid_amount input").value;
    if (!paid_amount) paid_amount = 0;

    const pending_amount = total_amount - parseFloat(paid_amount);
    total_fields.querySelector(".pending_amount input").value = parseFloat(pending_amount);

  };

  document.querySelector('#stock_purchases_relate_supplier')?.addEventListener('change', function() {
    if (this.checked){
      update_stock_purchase_totals(this);
    }
  });

  document.querySelector('form .paid_amount input')?.addEventListener('keyup', function (e) {
    const form = e.target.closest('form');
    const paid_amount = form.querySelector(".paid_amount input").value;
    if (!paid_amount) return;

    const total_amount = form.querySelector(".total_amount input").value;
    if (!total_amount) return;
    const pending_amount = parseFloat(total_amount) - parseFloat(paid_amount);
    form.querySelector(".pending_amount input").value = parseFloat(pending_amount);
  });

  document.getElementById('select-menu-item-ingredient')?.addEventListener("change", function (e) {
    const item = e.target.value;
    window.location.href = window.location.href.split('?')[0] + '?item=' + item;

  });

  documentOn('keyup', 'form.edit_stock_purchase .unit_price input, form.new_stock_purchase .unit_price input, form.edit_stock_purchase .item_tax input, form.new_stock_purchase .item_tax input, form.edit_stock_purchase .item_purchase_discount input, form.new_stock_purchase .item_purchase_discount input, form.edit_stock_purchase .item_purchase_charges input, form.new_stock_purchase .item_purchase_charges input', function (e) {
    const tr = e.target.closest('.stock_item_purchase');
    const quantity = tr.querySelector(".quantity input").value;
    const unit_price = tr.querySelector(".unit_price input").value;

    // Modify the logic below as per your requirement on how item_tax affects the calculation
    if (!!quantity && !!unit_price) {
      let adjusted_price = parseFloat(unit_price) * parseFloat(quantity);

      tr.querySelector(".adjusted_price input").value = adjusted_price;
      update_stock_purchase_totals(this);
    }
  });


  document.querySelector("#stock_purchase_fees_attributes_0_amount")?.addEventListener("keyup", function (e) {
    update_stock_purchase_totals(this);
  })

  documentOn('keyup', 'form.edit_stock_purchase .quantity input, form.new_stock_purchase .quantity input', function (e) {
    const tr = e.target.closest('.stock_item_purchase');
    const quantity = e.target.value;
    const unit_price = tr.querySelector(".unit_price input").value;
    if (!!quantity && !!unit_price) {
      tr.querySelector(".adjusted_price input").value = parseFloat(unit_price) * parseFloat(quantity);
      update_stock_purchase_totals(this);
    }
  });

  documentOn('keyup', 'form.edit_stock_purchase .paid_amount input, form.new_stock_purchase .paid_amount input', function (e) {
    const this_div = e.target.closest('.nested-fields');
    const total_amount = this_div.querySelector('.total_amount input');
    const pending_amount = this_div.querySelector('.pending_amount input');
    pending_amount.value = parseFloat(total_amount.value) - parseFloat(e.target.value);
  });


  documentOn('change', '.switch_fields', function (e) {
    if (e.target.checked) {
      document.querySelectorAll(e.target.dataset.selector).forEach(el => {
        el.classList.remove('hidden');
        el.querySelectorAll('input, select').forEach(el => {
          el.disabled = false;
        });
      });
    }
    else {
      document.querySelectorAll(e.target.dataset.selector).forEach(el => {
        el.classList.add('hidden');
        el.querySelectorAll('input, select').forEach(el => {
          el.disabled = true;
        });
      });
    }
  });

  documentOn('change', '.manage_inventory', function (e) {
    if (e.target.querySelector(".manage_inventory INPUT[type='checkbox']").checked) {
      removeClass('.show_inventory', 'hidden');
      addClass('.menu-item-form', 'hidden');
      removeClass('.menu-item-inventory-form', 'hidden');
    }
    else {
      addClass('.show_inventory', 'hidden');
      addClass('.menu-item-inventory-form', 'hidden');
      removeClass('.menu-item-form', 'hidden');
    }
  });

  documentOn('click', '.new-menu-item', function (e) {
    document.querySelector('#add_menu_item_modal').removeAttribute("tabindex");
  });

  documentOn('click', '.update-stock-item', function (e) {
    e.target.disabled = true;
    let stock_items_checked = {};
    let url = e.target.dataset.url;
    let ingredient_id = e.target.ingredientId;
    let list = document.querySelectorAll(".stock-item-settings input[type='checkbox']");
    list.forEach(checkbox => {
      if (checkbox.checked == true) {
        let stock_items_tags = checkbox.closest('.stock_items_row').getElementsByTagName('input');
        let stock_item_id = stock_items_tags[0].id;
        stock_items_checked[`${stock_item_id}`] = {};
        stock_items_checked[`${stock_item_id}`][stock_items_tags[1].name] = stock_items_tags[1].value;
        stock_items_checked[`${stock_item_id}`][stock_items_tags[2].name] = stock_items_tags[2].value;
        stock_items_checked[`${stock_item_id}`][stock_items_tags[3].name] = stock_items_tags[3].value;
        stock_items_checked[`${stock_item_id}`][stock_items_tags[4].name] = stock_items_tags[4].value;
        let stock_items_selects = checkbox.closest('.stock_items_row').getElementsByTagName('select');
        stock_items_checked[`${stock_item_id}`][stock_items_selects[0].name] = stock_items_selects[0].value;
      }
    });
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        stock_items: stock_items_checked,
        ingredient_id: ingredient_id
      })
    }).then(response => response.json()).then(data => {
      if(data.length > 0) {
        let ingClassName = `.${data[0].text}_${data[0].id}`;
        if(document.querySelector(ingClassName)) {
          document.querySelector(ingClassName).value = data[0].default_stock_location_stock_item_quantity;
        }
      }
      const elem = e.target.closest('#edit-ingredient-stock');
      elem.hidePopover();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  });
});


