import { loadTagIt } from '../shared/load-dynamic';
document.addEventListener('DOMContentLoaded', function() {
  //Social Sharing SMS, Email Push Tagit Initializing
  let me;
  if (document.querySelector('#sms_likes_campaign_item_list')) {
    loadTagIt().then(() => {
      $('#sms_likes_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('sms_likes_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  if (document.querySelector('#sms_orders_campaign_item_list')) {
    loadTagIt().then(() => {
      m$('#sms_orders_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('sms_orders_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  if (document.querySelector('#email_likes_campaign_item_list')) {
    loadTagIt().then(() => {
      $('#email_likes_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('email_likes_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  if (document.querySelector('#email_orders_campaign_item_list')) {
    loadTagIt().then(() => {
      $('#email_orders_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('email_orders_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  if (document.querySelector('#push_likes_campaign_item_list')) {
    loadTagIt().then(() => {
      $('#push_likes_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('push_likes_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }

  if (document.querySelector('#push_orders_campaign_item_list')) {
    loadTagIt().then(() => {
      $('#push_orders_campaign_item_list').tagit({
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.getElementById('push_orders_campaign_item_list').dataset.autocompleteSource
        },
        placeholderText: 'Type in items'
      });
    });
  }


  //Social Sharing SMS, Email, Push Creating Buttons
  document.getElementById('new_sms_likes')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_sms_likes_new').style.display = '';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('new_sms_likes').style.display = 'none';
  });
  document.getElementById('cancel_sms_likes_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
  });

  document.getElementById('new_sms_orders')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_sms_orders_new').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('new_sms_orders').style.display = 'none';
  });
  document.getElementById('cancel_sms_orders_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
  });

  document.getElementById('new_email_likes')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_email_likes_new').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('new_email_likes').style.display = 'none';
  });
  document.getElementById('cancel_email_likes_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
  });

  document.getElementById('new_email_orders')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_email_orders_new').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('new_email_orders').style.display = 'none';
  });
  document.getElementById('cancel_email_orders_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
  });

  document.getElementById('new_push_likes')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_push_likes_new').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = 'none';
  });
  document.getElementById('cancel_push_likes_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
  });

  document.getElementById('new_push_orders')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('social_campaign_push_orders_new').style.display = '';
    document.getElementById('social_campaign_sms_likes_new').style.display = 'none';
    document.getElementById('social_campaign_sms_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_orders_new').style.display = 'none';
    document.getElementById('social_campaign_email_likes_new').style.display = 'none';
    document.getElementById('social_campaign_push_likes_new').style.display = 'none';
    document.getElementById('new_sms_likes').style.display = '';
    document.getElementById('new_email_likes').style.display = '';
    document.getElementById('new_email_orders').style.display = '';
    document.getElementById('new_push_likes').style.display = '';
    document.getElementById('new_sms_orders').style.display = '';
    document.getElementById('new_push_orders').style.display = 'none';
  });
  document.getElementById('cancel_push_orders_button')?.addEventListener('click', function(e) {
    e.preventDefault();
    document.getElementById('new_push_orders').style.display = '';
    document.getElementById('social_campaign_push_orders_new').style.display = 'none';
  });

  let counter = 0;
  const length = $('.marketing_campaign_item_list').length;
  while (counter < length) {
    loadTagIt().then(() => {
      $('#marketing_campaign_item_' + counter).tagit({
        fieldName: "social_campaign[item_list]",
        singleField: true,
        allowSpaces: true,
        autocomplete: {
          minLength: 2,
          source: document.querySelector('#marketing_campaign_item_' + counter).getAttribute('data-source')
        },
        placeholderText: 'Type in items',
        beforeTagRemoved(e, ui) {
          let tag_index;
          const ul = document.getElementById(ui.tag.parent().getAttribute('id'));
          const items = ul.getElementsByTagName("li");
          let count = 0;
          while (count < items.length) {
            const str = items[count].textContent.slice(0, -1);
            if (ui.tagLabel === str) {
              tag_index = count;
            }
            count = count + 1;
          }
          const div_id = ui.tag.parentNode.getAttribute('id').split("_")[3];
          const arra = document.getElementsByName("social_campaign[item_list]")[div_id].value.split(",");
          arra.splice(tag_index, 1);
          document.getElementsByName("social_campaign[item_list]")[div_id].value = arra.toString();
        }
      });
    });
  }
});



