window.RSVP = require('rsvp');
//window.Sha256 = require('sha-256')
import { api, configs, printers, websocket, print } from 'qz-tray';
import sha256 from "js-sha256";
//Q = require('q')

import { I18nRails } from './rails-i18n-js';
import EposTray from './epos-tray';
import EposCordova from './epos-cordova';

api.setSha256Type(data => sha256(data));

//qz.api.setPromiseType(Q.Promise);
//qz.api.setPromiseType( promise = (resolver) -> return new Promise(resolver))
// qz.api.setPromiseType( promise = resolver => new RSVP.Promise(resolver));
api.setPromiseType(function promise(resolver) {
  return new Promise(resolver);
});

export default class ThermalPrinter {
  constructor(config) {
    this.reset = this.reset.bind(this);
    this.addLineBreak = this.addLineBreak.bind(this);
    this.getOutput = this.getOutput.bind(this);
    if (config == null) { config = { host: '192.168.1.107', port: 9100 }; }
    this.config = config;
    this.setAutoWidth();

    this.output = '';
    this.printCall = null;
    this.lineBreak = "<br/>";
    this.spaces = ' ';

    this.width = 24;

    this.spaceOrderNo = 10;
    this.spaceOrderDate = 40;
    this.spaceDescription = 20;
    this.spaceId = 12;
    this.spaceDate = 28;

    this.lblDescription = 'Description';
    this.lblQty = 'Qty';
    this.lblPrice = 'Price';
    this.lblAmt = "Amt";
    this.lblSerialNo = "S#";
    this.lblOrderNo = "OrderNo"
    this.lblOrderType = "Type"
    this.lblPmtMethod = "Payment"
    this.lblItemName = "Name";
    this.lblItemCount = "Count";
    this.spaceItemName = 26;
    this.spaceItemCount = 6;
    this.spaceOrderType = 8;
    this.spacePmtMethod = 16;
    this.spaceSerialNo = 4;
    this.spaceQty = 6;
    this.spacePrice = 10;
    this.spaceAmt = 10;
    this.paddingTotals = 10;
    this.maxString = "Discount/Adjustment:";
  }

  setPrintCall(printCall) {
    this.printCall = printCall;
    return this;
  }

  setLineBreak(lb) {
    this.lineBreak = lb;
    return this;
  }

  setSpaces(spaces) {
    this.spaces = spaces;
    return this;
  }

  setWidth(w) {
    this.width = w;
    return this;
  }

  setAutoWidth() {
    if (this.config.paper_roll === '80mm') {
      this.width = 48;
    } else {
      this.width = 32;
    }
  }


  generateOrderReceipt(order) {
    return this.generateOrderReceiptPos(order);
  }

  generateOrderReceiptPos(order) {
    this.reset();

    const totalPrice = 0;

    this.addRaw("<div class='order_details'><b>Howmuch</b></div>");
    this.addRaw("<br/>");
    this.addDash();
    this.addRaw(`<div class='location_name'>${ order.location.name }</div>`);
    this.addRaw(`<div class='location_address'>${ order.location.address }</div>`);
    this.addRaw(`<div class='location_phone'>${ order.location.phone }</div>`);
    this.addLineBreak();
    this.addRaw(`<div class='order_number'>Order: ${ order.id }</div>`);
    this.addRaw(`<div class='order_user'>${ order.user.first_name }${ order.user.last_name }</div></div><div class='order_items'>`);

    this.addLineBreak();
    order.order_items.forEach((order_item) => {
      const menu_item = order_item.id;
      const item_total = parseFloat(order_item.price) * order_item.total;
      const item_price = order_item.price;
      this.addRaw(`<div class='order_item'>${ order_item.weighted_number }x ${ order_item.menu_item.title }                 ${ item_price }</div>`);
      //      @addRaw "<div class='order_item_price'>#{item_price}</div>"
      this.addLineBreak();
    });

    //alert(@output)
    this.addRaw("</div><div class='total_price'>");
    this.addLineBreak();
    this.addDash();

    this.addLine("Total", order.display_total);
    this.addDash();
    this.addRaw("</div>");
    return this.addRaw(`<div class='order_receipt_time'>${ new Date().toLocaleString() }</div>`);
  }

  generateOrderReceiptHtml(order) {
    this.reset();

    const totalPrice = 0;

    this.addRaw("<div class='order_details'><b>Howmuch</b>");
    this.addRaw("<br/>");
    this.addDash();
    this.addRaw(`<div class='location_name'>${ order.location.name }</div>`);
    this.addRaw(`<div class='location_address'>${ order.location.address }</div>`);
    this.addRaw(`<div class='location_phone_number'>${ order.location.phone }</div>`);
    this.addLineBreak();
    this.addRaw(`<div class='order_number'>Order: ${ order.id }</div>`);
    this.addRaw(`<div class='order_user'>${ order.user.first_name }${ order.user.last_name }</div></div><div class='order_items'>`);

    this.addLineBreak();
    order.order_items.forEach((item) => {
      const menu_item = item.id;
      const item_total = item.display_total;
      const item_price = item.display_price;
      this.addRaw(`<div class='order_item'>${ item.weighted_number }x ${ item.menu_item.title }</div>`);
      this.addRaw(`<div class='order_item_price'>${ item_price }</div>`);
      this.addLineBreak();
    });

    //alert(@output)
    this.addRaw("</div><div class='total_price'>");
    this.addLineBreak();
    this.addDash();

    this.addLine("Total", order.display_total);
    this.addDash();
    this.addRaw("</div>");
    return this.addRaw(`<div class='order_receipt_time'>${ new Date().toLocaleString() }</div>`);
  }

  printOrderReceipt(order, duplicate_bill = false) {
    //@generateOrderReceipt(order)
    return this.printPos(order, duplicate_bill);
  }
  printOrderReceiptBreak(order) {
    //@generateOrderReceipt(order)
    return this.printPosBreak(order);
  }
  printOrderReceiptSingle(order) {
    //@generateOrderReceipt(order)
    return this.printPosSingle(order);
  }
  printOrderReceiptDelta(order, items) {
    var delta = [];
    items.map(
      (item, key) => {
        if (item.item_id) {
          delta.push(item);
        }
      });

    return this.printPosDelta(order, delta);
  }

  printItemSalesReport(itemSalesReport){
    let detailsText;
    const eposTray = new EposTray(this.config);

    if (this.config.paper_roll === '58mm') {
      detailsText = eposTray.smallText;
    } else {
      detailsText = eposTray.normalText;
    }
    let me = this;
    eposTray.lineBreak().lineBreak();
    if (itemSalesReport.title === 'Item Sales Report'){
      let receipt_text = 'ITEM SALES REPORT';
      eposTray.normalText().centerAlign().text(receipt_text);
      eposTray.lineBreak().lineBreak();
      
      eposTray.centerAlign().printLogo(itemSalesReport.location.location_logo);
      eposTray.lineBreak().centerAlign().doubleFontSize().text(itemSalesReport.location.name);

      eposTray.lineBreak().lineBreak().standardFontSize();
      eposTray.lineBreak().text('==================================');

      eposTray.boldOn();
      eposTray.lineBreak().text(`FROM:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.from_time}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`TO:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.to_time}`);

      eposTray.lineBreak().text('==================================');

      eposTray.boldOn();
      eposTray.lineBreak().text(`TOTAL AMOUNT:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.total_price}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`TOTAL COUNT:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.total_count}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`TOTAL COST:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.total_cost}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`TOTAL DISCOUNT:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.total_discount}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`TOTAL PROFIT:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.total_profit}`);

      eposTray.lineBreak().text('==================================');

      eposTray.boldOn();
      if (itemSalesReport.user != null) {
          eposTray.lineBreak().text(`USER:`);
          eposTray.boldOf();
          eposTray.text(`${itemSalesReport.user}`);
      }
      if (itemSalesReport.menu_item != null) {
          eposTray.lineBreak().text(`MENU ITEM:`);
          eposTray.boldOf();
          eposTray.text(`${itemSalesReport.menu_item}`);
      }

      eposTray.boldOn();
      eposTray.lineBreak().text(`STATE:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.state}`);

      eposTray.boldOn();
      eposTray.lineBreak().text(`PER PAGE:`);
      eposTray.boldOf();
      eposTray.text(`${itemSalesReport.per_page}`);

      eposTray.boldOf();

      eposTray.lineBreak().text('==================================');

  
      eposTray.lineBreak().lineBreak();
      this.spaceAmt = 12;
      eposTray.centerAlign();
      eposTray.boldOn();
      detailsText().text('-----------------------------------------------').lineBreak();
      detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblItemName }`, this.spaceItemName) }${ this.paddedText(`${ this.lblItemCount }`, this.spaceItemCount) }${this.lblAmt.padStart(this.spaceAmt)}`);
      detailsText().lineBreak().text('-----------------------------------------------').lineBreak();
      eposTray.boldOf();
      detailsText().lineBreak().text(this.generateOrderItemThermal(itemSalesReport.records));
      detailsText().text('-----------------------------------------------').lineBreak();
    }
    eposTray.fifteenLineBreak();
    const data_to_print = eposTray.cutPaper().output();
    me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print);//.then ->
      // ThermalPrinter._qzDisconnect()
    }).catch(e => alert(`Error in printing: ${ e }`));
  }

  printSalesReport(salesReport) {

    let detailsText;
    const eposTray = new EposTray(this.config);

    if (this.config.paper_roll === '58mm') {
      detailsText = eposTray.smallText;
    } else {
      //Set small text for requirement, will remove this condition if font approved
      detailsText = eposTray.normalText;
    }

    // detailsText().centerAlign().text(salesReport.location_name);
    // eposTray.lineBreak();
    // detailsText().centerAlign().text(salesReport.title);
    eposTray.lineBreak();
    eposTray.lineBreak();
    // if (salesReport.title != 'Customers Report') {
    //   detailsText().centerAlign().text("From: ");
    //   detailsText().centerAlign().text(salesReport.from_time);
    //   eposTray.lineBreak();
    //   detailsText().centerAlign().text("To: ");
    //   detailsText().centerAlign().text(salesReport.to_time);
    //   eposTray.lineBreak();
    //   eposTray.lineBreak();
    // }

    let me = this;

    if (salesReport.title === 'Profit and Loss Report') {
      let records = salesReport.records;
      Object.keys(records).forEach(function(key) {
        detailsText().text(key.replace(/(?:_| |\b)(\w)/g, function($1) { return $1.toUpperCase().replace('_', ' '); }) + ': ');
        detailsText().centerAlign().text(records[key]);
        eposTray.lineBreak();
      });
    }
    else {
      const spacePerNameKey = 22;
      // if(salesReport.summary) {
      //   const summary = salesReport.summary
      //   const keys = Object.keys(summary);
      //   const spacePerKey = (this.width / keys.length) * 2.5;

      //   keys.forEach(function(key) {
      //     if (key === 'count' || key === 'type' || (key === 'price' && salesReport.title === 'Order Sales Report')) {
      //       detailsText().text(me.smallpaddedText(key.replace(/(?:_| |\b)(\w)/g, function($1) { return $1.toUpperCase().replace('_', ' '); }), 5, 3));
      //     }
      //     else if (key === 'channel') {
      //       detailsText().text(me.paddedTextStart(key.replace(/(?:_| |\b)(\w)/g, function($1) { return $1.toUpperCase().replace('_', ' '); }), spacePerKey));
      //     }
      //     else if (key === 'name') {
      //       detailsText().text(me.paddedTextName(' ' + key.toUpperCase(), spacePerNameKey));
      //     } else if (key == 'id') {
      //       detailsText().text(me.paddedText(' ' + key.toUpperCase(), spacePerKey));
      //     }
      //     else {
      //       detailsText().text(me.paddedText(key.replace(/(?:_| |\b)(\w)/g, function($1) { return $1.toUpperCase().replace('_', ' '); }), spacePerKey));
      //     }
      //   });
      //   eposTray.lineBreak();
      // }
      
      // if(salesReport.records && salesReport.records.length > 0) {
      //   const keys = Object.keys(salesReport.records[0]);
      //   const spacePerKey = (this.width / keys.length) * 2.5;
      //   salesReport.records.forEach(function(record) {
      //     let largerName = false;
      //     keys.forEach(function(key) {
      //       if (key === 'count' || key === 'quantity' || key === 'channel' || key === 'date') {
      //         detailsText().text(me.smallpaddedText(record[key], 6, 3));
      //       }
      //       else if (key === 'name') {
      //         if (record[key].length > 14) {
      //           largerName = true;
      //         }
      //         detailsText().text(me.paddedTextName(record[key].slice(0, 14), spacePerNameKey));
      //       }
      //       else {
      //         detailsText().text(me.paddedText(record[key], spacePerKey));
      //       }
      //     });
      //     eposTray.lineBreak();

      //     if (largerName) {
      //       keys.forEach(function(key) {
      //         if (key === 'count' || key === 'quantity' || key === 'channel' || key === 'date') {
      //           detailsText().text(me.smallpaddedText('', 6, 3));
      //         }
      //         else if (key === 'name') {
      //           detailsText().text(me.paddedTextName(record[key].slice(14), spacePerNameKey));
      //         }
      //         else {
      //           detailsText().text(me.paddedText('', spacePerKey));
      //         }
      //       });
      //       eposTray.lineBreak();
      //     }
      //   });
      // }
      if (salesReport.title === 'Order Sales Report'){
        let receipt_text = 'SALES REPORT';
        eposTray.normalText().centerAlign().text(receipt_text);
        eposTray.lineBreak().lineBreak();
        
        eposTray.centerAlign().printLogo(salesReport.location.location_logo);
        eposTray.lineBreak().centerAlign().doubleFontSize().text(salesReport.location_name);

        eposTray.lineBreak().lineBreak().standardFontSize();
        eposTray.lineBreak().text('==================================');

        eposTray.boldOn();
        eposTray.lineBreak().text(`FROM:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.from_time}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TO:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.to_time}`);

        eposTray.lineBreak().text('==================================');

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL SALES:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_sales}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL ORDERS:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_orders_count}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`DELIVERY:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.order_counts.delivery}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`EAT-IN:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.order_counts['eat-in']}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`PICKUP:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.order_counts.pickup}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL ITEM SALES:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_item_sales}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL DISCOUNT:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_discount}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL FEES:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_fees}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`TOTAL TAX:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.total_tax}`);

        eposTray.lineBreak().text('==================================');

        eposTray.boldOn();
        salesReport.payments_grouped.forEach(pg => {
            eposTray.lineBreak().text(`${pg.name.toUpperCase()}:`);
            eposTray.boldOf();
            eposTray.text(`${pg.total_amount}`);
        });
        eposTray.boldOf();

        eposTray.lineBreak().text('==================================');

        eposTray.boldOn();
        if (salesReport.user != null) {
            eposTray.lineBreak().text(`USER:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.user}`);
        }
        if (salesReport.mode_type != null) {
            eposTray.lineBreak().text(`TYPE:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.mode_type}`);
        }
        if (salesReport.employee != null) {
            eposTray.lineBreak().text(`EMPLOYEE:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.employee}`);
        }
        if (salesReport.drawer != null) {
            eposTray.lineBreak().text(`DRAWER:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.drawer}`);
        }
        if (salesReport.payments_type != null) {
            eposTray.lineBreak().text(`PAYMENT TYPE:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.payments_type}`);
        }
        if (salesReport.shift != null) {
            eposTray.lineBreak().text(`SHIFT:`);
            eposTray.boldOf();
            eposTray.text(`${salesReport.shift}`);
        }

        eposTray.boldOn();
        eposTray.lineBreak().text(`STATE:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.state}`);

        eposTray.boldOn();
        eposTray.lineBreak().text(`PER PAGE:`);
        eposTray.boldOf();
        eposTray.text(`${salesReport.per_page}`);

        eposTray.boldOf();

        eposTray.lineBreak().text('==================================');

    
        eposTray.lineBreak().lineBreak();
        // this.spaceAmt = 10;
        this.spaceOrderNo = 18;
        eposTray.centerAlign();
        eposTray.boldOn();
        detailsText().text('-----------------------------------------------').lineBreak();
        detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblOrderNo }`, this.spaceOrderNo) }${ this.paddedText(`${ this.lblOrderType }`, this.spaceOrderType) }${ this.paddedText(`${ this.lblAmt }`, this.spaceAmt) }${ this.paddedText(`${ this.lblPmtMethod }`, this.spacePmtMethod) }`);
        detailsText().lineBreak().text('-----------------------------------------------').lineBreak();
        eposTray.boldOf();
        detailsText().lineBreak().text(this.generateOrderThermal(salesReport.records));
        detailsText().text('-----------------------------------------------').lineBreak();
      }
    }
    //    alert(eposTray.output())
    //    return
    eposTray.fifteenLineBreak();
    const data_to_print = eposTray.cutPaper().output();
    me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print);//.then ->
      // ThermalPrinter._qzDisconnect()
    }).catch(e => alert(`Error in printing: ${ e }`));
  }

  printIncomingOrder(order) {
    this.reset();

    this.addLineBreak();
    this.addLineBreak();

    this.printPos(order);
  }

  printReceivedOrder(order) {
    this.reset();

    this.addLineBreak();
    this.addLineBreak();
    this.addDash();


    this.addLine("New Order!");
    this.addLine(`Received ${ I18nRails.strftime(new Date(), '%H:%M') }`);


    this.addLineBreak();
    this.addLineBreak();
    this.addLineBreak();

    this.printPos(order);
  }

  printAcceptedOrder(order, time) {
    this.reset();

    //@addDash()

    this.addDash();

    this.addFullOrder(order);

    if (order.hasDelivery()) { this.addDeliveryAddress(order); }

    this.addDash();

    //    @addLine "Btl.: "+I18nRails.t("order_dock.order.payment_status.#{order.paymentStatus()}")
    if (!!order.payments && !!order.payments.payment_method) {
      this.addLine(`${ order.payments.payment_method.name }.`);
    }
    if (!!order.bill_address && !!order.bill_address.firstname) {
      this.addLine(`${ order.bill_address.firstname } ${ order.bill_address.lastname }.`);
      this.addLine(`${ order.bill_address.phone }`);
    } else {
      this.addLine(order.email);
      this.addLine(`${ order.bill_address.phone }`);
    }
    this.addLineBreak();
    this.addLineBreak();
    //    if order.hasDelivery()
    //      @addLine order.paymentAnnotation() if order.paymentAnnotation()
    //      @addLine "For delivery #{I18nRails.strftime(order.readyAt(), '%H:%M')}"
    //      @addLine "If: (#{time} min.)" if time
    //#    if order.hasTable()
    //#      @addLine "TO BORD:  " + order.table_number
    //#    else 
    //    if order.want_at
    //      @addLine "PREPARED"
    //      if Pretty.days_int_diff(order.readyAt()) is 0
    //        @addLine I18nRails.strftime(order.readyAt(), "%H:%M")
    //      else
    //        @addLine I18nRails.strftime(order.readyAt(), "%A %H:%M").toUpperCase()
    //    else
    //      I18nRails.t("print.ready_at", {ready_at: I18nRails.strftime(order.readyAt(), "%H:%M")})
    //    #if order.delivery_address and order.delivery_address.lat?
    //      #@addRaw "<img src=\"http://maps.googleapis.com/maps/api/staticmap?center=#{order.delivery_address.lat},#{order.delivery_address.lng}&zoom=14&format=png&sensor=false&scale=2&size=100x100&maptype=roadmap&style=visibility:off&style=feature:road.local|visibility:simplified|invert_lightness:true&style=feature:road.arterial|visibility:simplified|invert_lightness:true&style=feature:landscape.natural|lightness:100|visibility:simplified&style=feature:road.highway|visibility:simplified|lightness:-100&style=feature:transit.station.rail|visibility:simplified|saturation:-100&markers=color:black|label:0|#{order.delivery_address.lat},#{order.delivery_address.lng}\" width=\"200\">"
    //      #@addLineBreak()
    //
    //    if order.hasDelivery()
    //      @addLineBreak()
    //      @addLine "directions:"
    //      @addRaw "<div id='qrcode' style='width:100px; height:100px; margin-top:1px;'></div>"
    //      
    this.addLineBreak();
    this.addLineBreak();

    this.printPos(order);
    this.addLineBreak();
    this.addLine("---");
  }

  printGiftCard(giftcard) {
    this.reset();

    if ((window.cordova == null) && IpadLocation.find(window.global.location_id) && IpadLocation.find(window.global.location_id).full_img_high_url) {
      this.addRaw(`<img src='${ IpadLocation.find(window.global.location_id).full_img_high_url }' width='200px'>`);
    }
    this.addLineBreak();
    this.addLine(`Gavekort til ${ giftcard.restrictedTo() }:`);
    this.addLineBreak();

    this.addLine(`      ${ giftcard.activation_code }`);

    this.addLineBreak();

    let item_total = "Rs.100"; //I18nRails.toCurrency(+giftcard.start_amount, {unit: Ipad.currency_symbol(), strip_insignificant_zeros: true})
    this.addLine("Price:", `${ item_total }`);
    item_total = "Rs.100"; //I18nRails.toCurrency(+giftcard.start_credits, {unit: Ipad.currency_symbol(), strip_insignificant_zeros: true})
    this.addLine("Værdi:", `${ item_total }`);
    this.addLineBreak();
    this.addText("Indløs koden tilsidst ved næste bestilling på:");
    this.addLineBreak();
    if (giftcard.pretty_url) {
      this.addText(`${ giftcard.pretty_url }`);
    }
    this.addLineBreak();
    this.addLineBreak();
  }

  //@printPos(giftcard.user)

  paddedText(str, length) {
    str = `${ str }`;
    return str.length < length ? str + ' '.repeat(length - str.length) : str.slice(0, length);
  }

  paddedTextName(str, length) {
    str = `${ str }`;
    if (str.length < length) {
      //add spaces
      return `${ str }${ Array(length - (str.length) - 1).join(' ') }`;
    } else {
      str = str.slice(0, length - 1);
    }
  }

  alignLeftRight(leftText, rightText, totalWidth = 48) {
    const spaceCount = totalWidth - (leftText.length + rightText.length);
    return `${leftText}${' '.repeat(spaceCount > 0 ? spaceCount : 1)}${rightText}`;
  }
  
  paddedTextStart(str, length) {
    str = `${ str }`;
    if (str.length < length) {
      //add spaces
      return `${ Array(length - (str.length) - 1).join(' ') }${ str }`;
    } else {
      return str.slice(0, length - 1);
    }
  }

  smallpaddedText(str, start, end) {
    str = `${ str }`;
    //add spaces
    return `${ Array(start).join(' ') }${ str }${ Array(end).join(' ') }`;
  }

  generateFormattedText(str, count, totalLength) {
    //add desired string
    return `${ str }${ Array(count).join(str) }${ Array(totalLength - count - 1).join(' ') }`;
  }
  formatCurrency(price) {
    return I18nRails.numberToCurrency(price, { strip_insignificant_zeros: true, unit: '' });
  }

  showUnlessZero(charges, text) {
    //    if Math.abs(parseFloat(charges)) < 0.009
    //      '0'
    //    else
    //      text
    return text;
  }

  //  successPrinterListHandler : (list) ->
  //    #connect function either takes host, or name, or uuid
  //#    cordova.plugins.ThermalPrinter.connect({name: list[0]['name']})
  //#    cordova.plugins.ThermalPrinter.connect({uuid: list[0]['uuid']})
  //    #cordova.plugins.ThermalPrinter.connect({name: list[0]['name']})
  //    @printCordova58mm(order,{name: list[0]['name'], port: 1000 })
  //
  //    console.log "success"
  //    return 

  failurePrinterListHandler(list) {
  }

  static discoverBtPrinters(callback) {
    //    cordova.plugins.ThermalPrinter.discoverPrinters(@successPrinterListHandler)
    cordova.plugins.ThermalPrinter.discoverPrinters(callback);
  }

  static discoverUSBPrinters(queryopt, callback) {
    //    cordova.plugins.ThermalPrinter.discoverPrinters(@successPrinterListHandler)

    ThermalPrinter._qzConnect().then(function() {
      if (!!queryopt) {
        printers.find(queryopt).then(callback);
      } else {
        printers.find().then(callback);
      }
    }).catch(function(e) {
      alert("Could not connect to QZ Tray.");
      callback([]);
    });
  }

  static discoveredPrintersList(list) {
    cordova.plugins.ThermalPrinter.connect({ name: list[0]['name'] });
  }

  generateOrderReceipt(order) {
    this.reset();

    this.addRaw("<div class='order_details'><b>Howmuch Regular Printer</b><br/>");
    this.addDash();
    this.addRaw(`<div class='location_name'>${ order.location.name }</div>`);
    this.addRaw(`<div class='location_address'>${ order.location.address }</div>`);
    this.addRaw(`<div class='location_phone'>${ order.location.phone }</div>`);

    this.addLineBreak();
    this.addRaw(`<div class='order_number'>Order: ${ order.id }</div>`);
    this.addRaw(`<div class='order_user'>${ order.user_name }</div></div><div class='order_items'>`);
    this.addLineBreak();
    order.order_items.forEach((order_item) => {
      const menu_item = order_item.id;
      const item_price = order_item.unit_price;
      const item_total = order_item.price;
      this.addRaw(`<div class='order_item'>${ order_item.weighted_number }x ${ order_item.menu_item.title }</div>`);
      this.addRaw(`<div class='order_item_price'>${ item_price }</div>`);
      this.addLineBreak();
      order.order_item_ingredients.forEach(order_item_ingredient => {
        if ((order_item.item_type === 'MenuItem') && !!order_item_ingredient.menu_item_ingredient.customizable) {
          this.addRaw(`<div class='order_item_ingredient'>${ order_item_ingredient.number }x ${ order_item_ingredient.menu_item_ingredient.ingredient.name }</div>`);
          this.addRaw(`<div class='order_item_ingredient_price'>${ order_item_ingredient.menu_item_ingredient.price }</div>`);
          this.addLineBreak();
        }
      });
    });


    //alert(@output)
    this.addRaw("</div><div class='total_price'>");
    this.addLineBreak();
    this.addDash();

    this.addLine("Total", order.price);
    this.addDash();
    this.addRaw("</div>");
    return this.addRaw(`<div class='order_receipt_time'>${ new Date().toLocaleString() }</div>`);
  }
  generateOrderItemsThermalSingle(order) {
    const results = [];
    let i = 0;
    const me = this;
    order.order_items.forEach(function(item) {
      let item_total;
      i = i + 1;
      if (item.item_type === 'MenuItem') {
        const menu_item = item.id;
        item_total = parseFloat(item.price) * item.weighted_number;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.menu_item.title }`, me.spaceDescription) }${ me.paddedText(`${ item.weighted_number }`, me.spaceQty) }\n`);
      } else {
        item_total = item.price;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.delivery.name }`, me.spaceDescription) }${ me.paddedText("", me.spaceQty) }\n`);
      }
    });
    return results.join('');
  }
  generateOrderItemsThermalDelta(order) {
    const results = [];
    let i = 0;
    const me = this;
    order.forEach(function(item) {
      let item_total;
      i = i + 1;
      if (item.item_type === 'MenuItem') {
        const menu_item = item.id;
        item_total = parseFloat(item.price) * item.weighted_number;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.title }`, me.spaceDescription) }${ me.paddedText(`${ item.weighted_number }`, me.spaceQty) }\n`);
      } else {
        item_total = item.price;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.delivery.name }`, me.spaceDescription) }${ me.paddedText("", me.spaceQty) }\n`);
      }
    });
    return results.join('');
  }

  adjustPmtMethod(payment_methods) {

    if (payment_methods === 'Credit Card')
    {
      return 'Card';
    }
    else {
      return payment_methods.length <= 6 ? payment_methods : payment_methods.length > 6 
        ? payment_methods.slice(0, 6) + "." 
        : payment_methods;
    }
  }
  
  generateOrderItemThermal(order_items) {
    this.spaceAmt = 12;
    const results = [];
    let i = 0;
    const me = this;
    order_items.forEach(function(order_item) {
      i = i + 1;
      results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ order_item.name }`, me.spaceItemName) }${ me.paddedText(`${ order_item.count }`, me.spaceItemCount) }${me.formatCurrency(order_item.price).padStart(me.spaceAmt)}\n`);
    });
  
    return results.join('');
  }  
  generateOrderThermal(orders) {
    this.spaceOrderNo = 18;
    const results = [];
    let i = 0;
    const me = this;
    orders.forEach(function(order) {
      const paymentMethod = me.adjustPmtMethod(order.payment_methods);
      i = i + 1;
      results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ order.number }`, me.spaceOrderNo) }${ me.paddedText(`${ order.mode_type }`, me.spaceOrderType) }${ me.paddedText(`${ me.formatCurrency(order.item_total_without_discount_ensured) }`, me.spaceAmt) }${ me.paddedText(`${ paymentMethod }`, me.spacePmtMethod) }\n`);
    });
  
    return results.join('');
  }  
  generateOrderItemsThermal(order) {
    const results = [];
    let i = 0;
    const me = this;
    order.order_items.forEach(function(item) {
      let item_total;
      i = i + 1;
      if (item.item_type === 'MenuItem') {
        const menu_item = item.id;
        item_total = parseFloat(item.price) * item.weighted_number;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.menu_item.title }`, me.spaceDescription) }${ me.paddedText(`${ item.weighted_number }`, me.spaceQty) }${ me.paddedText(`${ me.formatCurrency(item.price) }`, me.spacePrice) }${ me.paddedText(`${ me.formatCurrency(item_total) }`, me.spaceAmt) }\n`);
      } else {
        item_total = item.price;
        return results.push(`${ me.paddedText(`${ i }`, me.spaceSerialNo) }${ me.paddedText(`${ item.delivery.name }`, me.spaceDescription) }${ me.paddedText("", me.spaceQty) }${ me.paddedText(`${ me.formatCurrency(item.price) }`, me.spacePrice) }${ me.paddedText(`${ me.formatCurrency(item_total) }`, me.spaceAmt) }\n`);
      }
    });
    return results.join('');
  }

  printCordova58mm(order, duplicate_bill = false) {
    cordova.plugins.ThermalPrinter.connect(this.config);
    let receipt_text = 'Sales Receipt';
    if (duplicate_bill == 'true') {
      receipt_text = 'Duplicate ' + receipt_text;
    }
    eposCordova.centerAlign().boldText().lineBreak().text(order.location.name);
    eposCordova.boldTextOff().smallText().centerAlign().standardFontSize();
    eposCordova.text(`${ order.location.address }\nPHONE : ${ order.location.phone }\n---------------------\n${ `${ receipt_text }\nTransaction#:   ${ order.id }\nDate:   ${ new Date().toLocaleString() }\nCustomer name:   ${ order.user_name }\n---------------------` }`,

      eposCordova.centerAlign().normalText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }${ this.paddedText(`${ this.lblPrice }`, this.spacePrice) }${ this.paddedText(`${ this.lblAmt }`, this.spaceAmt) }`));
    eposCordova.lineBreak().text(`${ this.generateFormattedText("=", this.lblSerialNo.length, this.spaceSerialNo) }${ this.generateFormattedText("=", this.lblDescription.length, this.spaceDescription) }${ this.generateFormattedText("=", this.lblQty.length, this.spaceQty) }${ this.generateFormattedText("=", this.lblPrice.length, this.spacePrice) }${ this.generateFormattedText("=", this.lblAmt.length, this.spaceAmt) }`);
    eposCordova.lineBreak().text(this.generateOrderItemsThermal(order));

    eposCordova.rightAlign().smallText().text(this.getOrderTotalFormatted(order));
    eposCordova.centerAlign().text(`All prices are inclusive of taxes\n${ order.location.site_url }\nFeedback: ${ order.location.email }\n${ order.location.facebook_url }\n---------------------\n\n\n\n`);
    eposCordova.centerAlign().cutPaper();

    cordova.plugins.ThermalPrinter.print(eposCordova.output(), 'foodnerd');
  }

  printCordova80mm(order, duplicate_bill = false) {
    cordova.plugins.ThermalPrinter.connect(this.config);
    this.reset();
    this.addLine(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }${ this.paddedText(`${ this.lblPrice }`, this.spacePrice) }${ this.paddedText(`${ this.lblAmt }`, this.spaceAmt) }`);
    this.addLine(`${ this.generateFormattedText("=", this.lblSerialNo.length, this.spaceSerialNo) }${ this.generateFormattedText("=", this.lblDescription.length, this.spaceDescription) }${ this.generateFormattedText("=", this.lblQty.length, this.spaceQty) }${ this.generateFormattedText("=", this.lblPrice.length, this.spacePrice) }${ this.generateFormattedText("=", this.lblAmt.length, this.spaceAmt) }`);
    this.addRaw(this.generateOrderItemsThermal(order));

    const eposCordova = new EposCordova(this.config);

    eposCordova.centerAlign().doubleFontSize().lineBreak().text(order.location.name);
    eposCordova.boldTextOff().normalText().centerAlign().standardFontSize();
    let receipt_text = 'Sales Receipt';
    if (duplicate_bill == 'true') {
      receipt_text = 'Duplicate ' + receipt_text;
    }
    eposCordova.text(`${ order.location.address }\nPHONE : ${ order.location.phone }\n---------------------\n${ `${ receipt_text }\nTransaction#:   ${ order.id }\nDate:   ${ new Date().toLocaleString() }\nCustomer name:   ${ order.user_name }\n---------------------` }`);

    eposCordova.centerAlign().normalText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }${ this.paddedText(`${ this.lblPrice }`, this.spacePrice) }${ this.paddedText(`${ this.lblAmt }`, this.spaceAmt) }`);
    eposCordova.lineBreak().text(`${ this.generateFormattedText("=", this.lblSerialNo.length, this.spaceSerialNo) }${ this.generateFormattedText("=", this.lblDescription.length, this.spaceDescription) }${ this.generateFormattedText("=", this.lblQty.length, this.spaceQty) }${ this.generateFormattedText("=", this.lblPrice.length, this.spacePrice) }${ this.generateFormattedText("=", this.lblAmt.length, this.spaceAmt) }`);
    eposCordova.lineBreak().text(this.generateOrderItemsThermal(order));

    eposCordova.rightAlign().normalText().text(this.getOrderTotalFormatted(order));
    eposCordova.centerAlign().text(`All prices are inclusive of taxes\n${ order.location.site_url }\nFeedback: ${ order.location.email }\n${ order.location.facebook_url }\n---------------------\n\n\n\n`);
    eposCordova.cutPaper();

    cordova.plugins.ThermalPrinter.print(eposCordova.output(), 'foodnerd');
  }

  printCordova(order, duplicate_bill = false) {
    if (this.config.paper_roll === '80mm') {
      this.spaceDescription = 20;
      this.printCordova80mm(order);
    } else {
      this.spaceDescription = 16;
      this.printCordova58mm(order);
    }
  }

  generatePrint(text_to_print) {
    let detailsText;
    const eposTray = new EposTray(this.config);

    if (this.config.paper_roll === '58mm') {
      detailsText = eposTray.smallText;
    } else {
      detailsText = eposTray.normalText;
    }

    return detailsText().text(text_to_print).cutPaper().output();
  }

  getOrderTotalFormatted(order) {
    const text = [];
    let total_weighted_number = 0;

    order.order_items.forEach(function(item) {
      if (item.item_type === 'MenuItem') {
        total_weighted_number += item.weighted_number;
      }
    });

    text.push(this.alignLeftRight("Total Quantity:", total_weighted_number.toString()));
    text.push("\n");
    
    text.push(this.alignLeftRight("Item Total:", this.formatCurrency(order.item_total)));
    text.push("\n");

    text.push(this.alignLeftRight("Total Tax:", this.formatCurrency(order.tax)));
    text.push("\n");

    if (order.ccard_charges != null) {
      text.push(this.alignLeftRight("CC Payment Tax:", this.formatCurrency(order.ccard_charges)));
      text.push("\n");
    }

    text.push(this.alignLeftRight("Discount/Adjustment:", this.formatCurrency(order.discount)));
    text.push("\n");

    if (order.service_charges != 0)
    {
      text.push(this.alignLeftRight("Services Charges:", this.formatCurrency(order.service_charges)));
      text.push("\n");
    }

    if (order.delivery_total != 0){
      text.push(this.alignLeftRight("Delivery Charges:", this.formatCurrency(order.delivery_total)));
      text.push("\n");
    }

    text.push(this.alignLeftRight("Total:", this.formatCurrency(order.final_price)));
    text.push("\n\n");

    return text.join('');
  }

  printTray(order, duplicate_bill = false) {
    let detailsText;
    const eposTray = new EposTray(this.config);
    if (this.config.paper_roll === '80mm') {
      this.spaceDescription = 18;
      detailsText = eposTray.normalText;
    } else {
      detailsText = eposTray.smallText;
      this.spaceDescription = 16;
    }
    let me = this;
    let receipt_text = 'Sales Receipt';
    if (duplicate_bill == 'true') {
      receipt_text = 'Duplicate ' + receipt_text;
    }
    eposTray.normalText().centerAlign().text(receipt_text),
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak();

    eposTray.centerAlign().printLogo(order.location.location_logo);
    eposTray.lineBreak().centerAlign().doubleFontSize().text(order.location.name);
    eposTray.lineBreak().lineBreak();

    detailsText().centerAlign().standardFontSize().text(order.location.address);
    eposTray.lineBreak().text(`Order Number: ${ order.number }`);
    eposTray.lineBreak().text(`PHONE : ${ order.location.phone }`);
    eposTray.lineBreak().text(`Order Type#:   ${ order.mode_type }`);
    const paymentMethod = order.payments && order.payments.length > 0 
      ? order.payments[0].type.split('::')[1] || 'N/A'
      : 'N/A';
    eposTray.lineBreak().text(`Payment Method: ${paymentMethod}`);
    eposTray.lineBreak().text(`Date:   ${ new Date().toLocaleString() }`);
    if (order.customer) {
      eposTray.lineBreak().text(`Customer Name:   ${ order.customer.name } `);
      eposTray.lineBreak().text(`Customer Email:   ${ order.customer.email } `);
      eposTray.lineBreak().text(`Customer Phone:   ${ order.customer.phone } `);
    }
    eposTray.lineBreak().lineBreak();
    eposTray.centerAlign();
    detailsText().text('-----------------------------------------------').lineBreak();
    detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }${ this.paddedText(`${ this.lblPrice }`, this.spacePrice) }${ this.paddedText(`${ this.lblAmt }`, this.spaceAmt) }`);
    detailsText().lineBreak().text('-----------------------------------------------').lineBreak();
    detailsText().lineBreak().text(this.generateOrderItemsThermal(order));
    detailsText().lineBreak().text('-----------------------------------------------').lineBreak();

    eposTray.rightAlign();
    eposTray.text(this.getOrderTotalFormatted(order));
    eposTray.lineBreak().lineBreak();
    if (order.location.hosted_data) {
      const hostedData = order.location.hosted_data;
      if (hostedData.pos_receipt_footer_line_1) {
        eposTray.centerAlign().text(hostedData.pos_receipt_footer_line_1).lineBreak();
      }
      else{
        eposTray.lineBreak();
      }
      if (hostedData.pos_receipt_footer_line_2) {
        eposTray.centerAlign().text(hostedData.pos_receipt_footer_line_2);
        eposTray.lineBreak().text("---------------------").lineBreak();
      }
      else{
        eposTray.lineBreak();
      }
      if (hostedData.pos_receipt_footer_line_3) {
        eposTray.centerAlign().text(hostedData.pos_receipt_footer_line_3);
        eposTray.lineBreak().text("---------------------");
      }
    }
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak().lineBreak().lineBreak();
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak();

    eposTray.cutPaper();

    const data_to_print = eposTray.output();

    me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print);//.then ->
      // ThermalPrinter._qzDisconnect()
    }).catch(e => alert(`Error in printing: ${ e }`));
  }

  printTrayDelta(order, items) {
    let detailsText;
    const eposTray = new EposTray(this.config);
    if (this.config.paper_roll === '80mm') {
      this.spaceDescription = 20;
      detailsText = eposTray.normalText;
    } else {
      detailsText = eposTray.smallText;
      this.spaceDescription = 16;
    }

    eposTray.lineBreak().centerAlign().text(order.location.name);
    eposTray.lineBreak().lineBreak();

    eposTray.lineBreak().centerAlign().text('Token Receipt');
    eposTray.lineBreak().lineBreak();

    eposTray.normalText().centerAlign().text('',
      eposTray.lineBreak().text(`Transaction#:   ${ order.id }`));
    eposTray.lineBreak().text(`Server:   ${ order.user_name }`);
    eposTray.lineBreak().text(`Date:   ${ new Date().toLocaleString() }`);
    eposTray.lineBreak().text(`Order Type:   ${ order.mode_type }`);
    if (order.table_number) {
      eposTray.lineBreak().text(`Table Number:   ${ order.table_number }`);
    }
    eposTray.lineBreak().text('---------------------');
    eposTray.lineBreak().lineBreak();

    eposTray.centerAlign();
    detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }`);
    eposTray.lineBreak().lineBreak();
    detailsText().lineBreak().text(this.generateOrderItemsThermalDelta(items));
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak();

    eposTray.cutPaper();

    const data_to_print = eposTray.output();

    const me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print).then(function() { });
    }).catch(e => alert(`Error in printing: ${ e }`));
  }


  printTrayBreak(order) {



    let detailsText;
    const eposTray = new EposTray(this.config);
    if (this.config.paper_roll === '80mm') {
      this.spaceDescription = 20;
      detailsText = eposTray.normalText;
    } else {
      detailsText = eposTray.smallText;
      this.spaceDescription = 16;
    }

    eposTray.lineBreak().centerAlign().text(order.location.name);
    eposTray.lineBreak().lineBreak();
    eposTray.normalText().centerAlign().text(`Token Receipt`);

    eposTray.lineBreak().text(`Transaction#:   ${ order.id }`);
    eposTray.lineBreak().text(`Server:   ${ order.user_name }`);
    eposTray.lineBreak().text(`Date:   ${ new Date().toLocaleString() }`);
    eposTray.lineBreak().text('---------------------');
    eposTray.lineBreak().lineBreak();

    eposTray.centerAlign();

    detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }`);
    detailsText().lineBreak().text(`${ this.paddedText(order.serial, this.spaceSerialNo) }${ this.paddedText(order.description, this.spaceDescription) }${ this.paddedText(order.quantity, this.spaceQty) }`);
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak();


    eposTray.cutPaper();

    const data_to_print = eposTray.output();

    const me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print).then(function() { });
    }).catch(e => alert(`Error in printing: ${ e }`));
  }

  printTraySingle(order) {
    let detailsText;
    const eposTray = new EposTray(this.config);
    if (this.config.paper_roll === '80mm') {
      this.spaceDescription = 20;
      detailsText = eposTray.normalText;
    } else {
      detailsText = eposTray.smallText;
      this.spaceDescription = 16;
    }

    eposTray.lineBreak().centerAlign().text(order.location.name);
    eposTray.lineBreak().lineBreak();

    eposTray.lineBreak().centerAlign().text('Token Receipt');
    eposTray.lineBreak().lineBreak();

    eposTray.normalText().centerAlign().text('',
      eposTray.lineBreak().text(`Order#:   ${ order.number }`));
    eposTray.lineBreak().text(`Server:   ${ order.user_name }`);
    eposTray.lineBreak().text(`Date:   ${ new Date().toLocaleString() }`);
    eposTray.lineBreak().text(`Order Type:   ${ order.mode_type }`);
    if (order.table_number) {
      eposTray.lineBreak().text(`Table Number:   ${ order.table_number }`);
    }
    eposTray.lineBreak().text('---------------------');
    eposTray.lineBreak().lineBreak();

    eposTray.centerAlign();
    detailsText().text(`${ this.paddedText(`${ this.lblSerialNo }`, this.spaceSerialNo) }${ this.paddedText(`${ this.lblDescription }`, this.spaceDescription) }${ this.paddedText(`${ this.lblQty }`, this.spaceQty) }`);
    eposTray.lineBreak().lineBreak();
    detailsText().lineBreak().text(this.generateOrderItemsThermalSingle(order));
    eposTray.lineBreak().lineBreak().lineBreak().lineBreak();

    eposTray.cutPaper();

    const data_to_print = eposTray.output();

    const me = this;
    ThermalPrinter._qzConnect().then(function() {
      const cfg = configs.create(me.config);
      ThermalPrinter._qzPrint(cfg, data_to_print).then(function() { });
    }).catch(e => alert(`Error in printing: ${ e }`));
  }
  static _qzConnect() {
    return new Promise(function(resolve, reject) {
      if (websocket.isActive()) {
        resolve();
      } else {
        websocket.connect().then(resolve, function() {
          window.location.assign("qz:launch");
          websocket.connect().then(resolve, reject);
        });
      }
    });
  }

  static _qzDisconnect() {
    return new Promise(function(resolve, reject) {
      if (websocket.isActive()) {
        resolve();
      } else {
        websocket.disconnect().then(resolve, () => alert("error while disconnecting printer"));
      }
    });
  }

  static _qzPrint(cfg, data_to_print) {
    print(cfg, data_to_print);
  }

  openCashDrawer() {
    if (!!window.cordova) {
      cordova.plugins.ThermalPrinter.connect(this.config);
      cordova.plugins.ThermalPrinter.openCasher();
    } else {
      const me = this;
      ThermalPrinter._qzConnect().then(function() {
        const cfg = configs.create(me.config);
        ThermalPrinter._qzPrint(cfg, [EposTray.open_drawer]).then(function() { });
      }).catch(e => alert(`Error in opening cash drawer: ${ e }`));
    }
  }
  printPosDelta(order, items) {
    if (!!window.cordova) {
      return this.printCordova(order);
      //cordova.plugins.printer.print("<html><body><head><style>#{styling}</style></head>#{@output}</body></html>", 'Howmuch');
    } else {
      return this.printTrayDelta(order, items);
    }
  }
  printPosSingle(order) {
    if (!!window.cordova) {
      return this.printCordova(order);
      //cordova.plugins.printer.print("<html><body><head><style>#{styling}</style></head>#{@output}</body></html>", 'Howmuch');
    } else {
      return this.printTraySingle(order);
    }
  }
  printPosBreak(order) {
    if (!!window.cordova) {
      return this.printCordova(order);
      //cordova.plugins.printer.print("<html><body><head><style>#{styling}</style></head>#{@output}</body></html>", 'Howmuch');
    } else {
      return order.order_items.map((value, i) => {
        console.log("Entered");
        var val = {};
        val.location = order.location;
        val.id = order.id;
        val.user_name = order.user_name;
        val.serial = 1;
        val.description = value.title;
        val.quantity = value.weighted_number;
        // Return the element. Also pass key 
        console.log(val);
        return this.printTrayBreak(val);

      });

    }
  }
  printPos(order, duplicate_bill = false) {
    if (!!window.cordova) {
      return this.printCordova(order, duplicate_bill);
      //cordova.plugins.printer.print("<html><body><head><style>#{styling}</style></head>#{@output}</body></html>", 'Howmuch');
    } else {
      return this.printTray(order, duplicate_bill);
    }
  }
  //console.log @output
  //@printHtml(@output)

  printHtml(html) {
    // ASSERT: At this point, we know that the current jQuery
    // collection (as defined by THIS), contains only one
    // printable element.
    // Create a random name for the print frame.
    const strFrameName = 'printer-' + (new Date).getTime();
    // Create an iFrame with the new name.
    const jFrame = document.querySelector('<iframe name=\'' + strFrameName + '\'>');
    // Hide the frame (sort of) and attach to the body.
    jFrame.style.width = '1px';
    jFrame.style.height = '1px';
    jFrame.style.position = 'absolute';
    jFrame.style.left = '-9999px';
    document.body.prepend(jFrame);
    // Get a FRAMES reference to the new frame.
    const objFrame = window.frames[strFrameName];
    // Get a reference to the DOM in the new frame.
    const objDoc = objFrame.document;
    // Grab all the style tags and copy to the new
    // document so that we capture look and feel of
    // the current document.
    // Create a temp document DIV to hold the style tags.
    // This is the only way I could find to get the style
    // tags into IE.
    //jStyleDiv = $('<div>').append($('style').clone())
    const styles = `\
@page {margin:0 -6cm}
html {margin:0 6cm}

.order_details {
text-align: center;
}
.order_item {
float: left;
}
.order_item_price {
float: right;
}
.total_price {
text-align: right;
}
.order_items br {
  width: 100%;
  float: left;
  clear: both;
}
.order_receipt_time {
text-align: center;
}

.location_name {
font-weight: bold;
font-size: 30px;
}
.location_address .location_phone {
font-weight: bold;
font-size: 25px;
}\
`;
    // Write the HTML for the document. In this, we will
    // write out the HTML of the current element.
    objDoc.open();
    objDoc.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">');
    objDoc.write('<html moznomarginboxes mozdisallowselectionprint>');
    objDoc.write('<body>');
    objDoc.write('<head>');
    //    objDoc.write '<title>'
    //    objDoc.write document.title
    //    objDoc.write '</title>'
    objDoc.write('<style>');
    objDoc.write(styles);
    objDoc.write('</style>');
    objDoc.write('</head>');
    objDoc.write(html);
    objDoc.write('</body>');
    objDoc.write('</html>');
    objDoc.close();
    // Print the document.
    objFrame.focus();
    objFrame.print();
    // Have the frame remove itself in about a minute so that
    // we don't build up too many of these frames.
    return setTimeout((function() {
      jFrame.remove();
    }), 60 * 1000);
  }

  reset() {
    return this.output = "";
  }

  addFullOrder(order) {
    order.order_items.forEach((item) => {

      if (item.item_type === "Delivery") {
        const item_total = "Rs.100"; //I18nRails.toCurrency(+item.price, {unit: Ipad.currency_symbol(), strip_insignificant_zeros: true})
        this.addLine(`${ I18nRails.t('order_dock.order_item.deliveryfee') } ${ item_total }`);
      } else {
        const menu_item = IpadMenuItem.find(item.menu_item_id);
        let itemLine = "";
        if (item.weighted_number !== 1) { itemLine = `${ item.weighted_number }x `; }

        if ((menu_item.show_position || IpadSettings.get().ingrNumbers) && (!!menu_item.order_position)) {
          itemLine += `#${ menu_item.order_position } `;
        }

        if (!!item.component_option_value_id) {
          const option_value = menu_item.available_options[0].available_option_values.findBy('component_option_value_id', item.component_option_value_id);
          if (!!option_value && !!option_value.name) {
            if (menu_item.show_position) {
              itemLine += `${ option_value.name } `;
            } else {
              itemLine += `(${ option_value.name }) `;
            }
          }
        }

        if (menu_item.show_position) {
          itemLine += `(${ menu_item.title })`;
        } else {
          itemLine += `${ menu_item.title }`;
        }


        this.addText(itemLine);

        if (!!item.note) {
          this.addText(`\"${ item.note }\"`);
        }


        if (!!item.order_item_ingredients) {
          order.order_item_ingredients.forEach(ingr => {
            const ingredient = IpadIngredient.find(ingr.ingredient_id);
            let nmbr = (!!ingr.number) ? ingr.number - ingr.menu_item_ingredient.included : 0;
            const sign = nmbr > 0 ? "+" : nmbr < 0 ? "-" : "";
            nmbr = Math.abs(nmbr);
            const title = ingr.menu_item_ingredient_title;
            let line = "  " + (nmbr !== 1 ? `${ sign }${ nmbr }x ` : `${ sign }`);
            if (IpadSettings.get().ingrNumbers && ingredient.order_position) { line += ` #${ ingredient.order_position } `; }
            if (nmbr) {
              this.addText(line + `${ title }`);
            }
          });
        }
      }
    });
    this.addText(`IALT ${ order.price }`);
    if ((order.location_id === 57) && (order.payment_annotation === 'ccard_delivery')) {
      this.addLine("+5 KR DANKORT");
      return this.addText(`IALT ${ +parseFloat(order.price) + 5 }`);
    }
  }
  //totalPrice += item.price*item.weighted_number

  addDeliveryAddress(order) {
    this.addDash();
    this.addLine(`Levering (${ order.readyAtPretty() })`);

    const address = order.delivery_address;

    this.addText(address.name_door);
    this.addText(address.street);
    if (address.street2) { this.addText(address.street2); }
    return this.addText(address.zip + ' ' + (address.city ? address.city : ''));
  }


  addLineBreak() {
    return this.output += this.lineBreak;
  }

  addRaw(t) {
    return this.output += t;
  }

  addDash(width = null) {
    if (width == null) {
      ({
        width
      } = this);
    }
    for (let i = 1, end = width, asc = 1 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) { this.output += "-"; }
    return this.output += this.lineBreak;
  }

  addRawLine(string, before, width = null) {
    if (before == null) { before = ""; }
    if (width == null) {
      ({
        width
      } = this);
    }
    const bLength = before.length;
    const t = string.substr(0, width - bLength);
    this.output += `${ before }${ t }`;
    this.addLineBreak();

    return t.length;
  }

  addText(text, width = null) {
    if (width == null) {
      ({
        width
      } = this);
    }
    let lineLength = 0;
    //@addRaw("  ")
    const ff = text.split(" ");
    for (let i = 0; i < ff.length; i++) {
      const word = ff[i];
      lineLength += word.length + 1;
      if (lineLength > width) {
        lineLength = (word.length + 1) - 0;
        this.addLineBreak();
      }
      //@addRaw("  ")
      if (word.length > width) {
        this.addRaw(`${ word.substring(0, width) } `);
        this.addLineBreak();
        this.addRaw(`${ word.substring(width, word.length) } `);
        lineLength = (word.length - width) + 1;
      } else {
        this.addRaw(`${ word } `);
      }
    }

    return this.addLineBreak();
  }

  addLine(left, right, width = null) {
    if (right == null) { right = ""; }
    if (width == null) {
      ({
        width
      } = this);
    }
    width--;

    if (((left.length + right.length) > width) && (right.length > 0)) {
      left = left.substr(0, width - right.length);
    } else if (((left.length + right.length) < width) && (right.length > 0)) {
      while ((left.length + right.length) < width) {
        left += " ";
      }

    } else if (left.length > (width + 1)) {
      left = left.substr(0, width);
    }

    return this.output += `${ left } ${ right }${ this.lineBreak }`;
  }

  getOutput() {
    return this.output;
  }
};


